import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Select, Row, Card, Tabs } from 'antd';
import { getTranslationsForLang, SUPPORTED_LANGUAGES } from '../../../../utils/language';
import RichText from '../../../ui/forms/rich-text';
import Styles from '../themesForm.module.scss';
import { getDefaultCustomLinks, getDefaultLangs, getLanguageLabel, getPrivacyBodyForAdvancedConfig } from '../utils';
import CustomLinks from './custom-links';

// @flow
interface IAdvancedConfigurationProps {
  validations: Object;
  setFieldsValue: Function;
  getFieldValue: Function;
  onValuesChange: Function;
}

const AdvancedConfiguration = ({
  validations,
  setFieldsValue,
  getFieldValue,
  onValuesChange,
}: IAdvancedConfigurationProps) => {
  const currentSettings = getFieldValue('advancedCustomizationSettings');
  const initialLanguage = currentSettings && currentSettings.length > 0 ? currentSettings[0].language : 'en';
  const supportedLanguages = SUPPORTED_LANGUAGES.filter(lang => lang.value !== 'auto');

  const [tabId, setTabId] = useState(initialLanguage);
  const [languages, setLanguages] = useState(getDefaultLangs(currentSettings));

  const getInitialPrivacyBody = lang => {
    const current = getFieldValue('advancedCustomizationSettings');
    if (current) {
      const item = current.find(item => item.language === lang);
      return item && item.privacyBody ? item.privacyBody : '';
    }
    return '';
  };

  const tabItems = languages.map((lang, index) => {
    const translations = getTranslationsForLang(lang);

    return {
      key: lang,
      label: getLanguageLabel(lang),
      forceRender: true,
      children: (
        <Col span="24">
          <Form.Item noStyle name={['advancedCustomizationSettings', index, 'language']}></Form.Item>
          <Row gutter={16}>
            <Col span="12">
              <Form.Item label="Primary button text">
                <Input disabled placeholder={translations.agree || ''} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name={['advancedCustomizationSettings', index, 'secondaryButton']}
                label="Secondary button text"
                rules={validations.textInput}
              >
                <Input placeholder={translations.saveAndExit} maxLength={24} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form.Item
                label="Disagree button text/ “X” button tooltip"
                name={['advancedCustomizationSettings', index, 'disagreeButton']}
              >
                <Input placeholder="Disagree" maxLength={24} placeholder={translations.disagree} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form.Item
                label="Privacy Header"
                name={['advancedCustomizationSettings', index, 'privacyHeader']}
              >
                <Input  placeholder={translations.mockPurposesCopies.headline || "We value your privacy"} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              {
                <RichText
                  label="Privacy Body Copy"
                  input={{
                    name: `privacyBody-${lang}`,
                    value: getPrivacyBodyForAdvancedConfig(lang, currentSettings),
                    onChange: value => {
                      privacyBodyChange(value, lang);
                    },
                  }}
                  editorClassName="input-uspDnsText"
                  className="uspDnsText-container"
                  placeholder={translations.coreUiLabels.initScreenBodyService}
                />
              }
            </Col>
          </Row>
          <Form.Item noStyle name={['advancedCustomizationSettings', index, 'privacyBody']}></Form.Item>

          <CustomLinks
            lang={lang}
            index={index}
            validations={validations}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            fieldName={'advancedCustomizationSettings'}
          />
        </Col>
      ),
    };
  });

  const handleTabChange = id => {
    setTabId(id);
  };

  const handleLanguageChange = value => {
    const currentValues = getFieldValue('advancedCustomizationSettings');
    const added = value.find(item => !languages.includes(item));
    const removed = languages.find(item => !value.includes(item));
    if (added) {
      setFieldsValue({
        advancedCustomizationSettings: [
          ...currentValues,
          {
            language: added,
            privacyBody: getPrivacyBodyForAdvancedConfig(added, currentValues),
          },
        ],
      });
    }
    if (removed) {
      const updatedValues = currentValues.filter(item => item.language !== removed);
      setFieldsValue({ advancedCustomizationSettings: updatedValues });
      if (tabId === removed) {
        setTabId(value[0]);
      }
    }
    setLanguages(value);
  };

  const privacyBodyChange = (value, lang) => {
    const currentValues = getFieldValue('advancedCustomizationSettings');

    const updatedValues = currentValues.map(item => {
      if (item.language === lang) {
        return { ...item, privacyBody: value };
      }
      return item;
    });
    setFieldsValue({ advancedCustomizationSettings: updatedValues });
    onValuesChange();
  };

  return (
    <>
      <p style={{ marginBottom: '20px' }}> This will be applied for premium properties </p>
      <Card title="Choose Language" extra="GDPR">
        <Form.Item name="advancedConfigLanguages" label="Language" required>
          <Select
            defaultValue={languages}
            showSearch
            mode="multiple"
            onChange={handleLanguageChange}
            options={supportedLanguages}
            optionFilterProp="label"
            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
      </Card>
      <Card title="Customise Content" extra="GDPR">
        <Tabs
          size="small"
          type="card"
          onTabClick={handleTabChange}
          defaultActiveKey={tabId}
          items={tabItems}
          activeKey={tabId}
        />
      </Card>
    </>
  );
};

export default AdvancedConfiguration;
