// @flow
import React, { useState, useEffect } from 'react';

import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import Alert from '../../../../components/ui/alert';
import { BACK_TO_ALL_PROPERTIES_LABEL } from '../../../bulk-update/constants';
import ContentHeader from '../../../../components/ui/content-header';
import {
  CHILD_PURPOSE_ID, EEA_AND_UK, GENERAL_FORM_ERROR, SENSITIVE_PURPOSE_ID,
  XSS_ERROR_TEXT
} from '../../../../utils/constants';
import Loader from '../../../../components/ui/loader';
import SitesForm from '../../../../components/forms/sites';
import Styles from './sitesFormPage.module.scss';
import { setImageSizeParameters } from '../../../../utils/setImageSize';
import { isGPPSupported } from '../../../../utils/property';
import { country3, isFieldEnabled } from '../../../../utils/sites';

type Props = {
  themes: Array<iTheme>;
  sites: Array;
  versions: Array<string>;
  versionsV2: Array<string>;
  onSubmit: Function;
  initData: Object;
  title: string;
  toolTipTitle: String;
  pending: boolean;
  history: Object;
  fromSiteDetail: boolean;
  path: string;
  isPremium: boolean;
  dataTest: string;
  pcode: string;
  vendors: Object;
}

const deleteFieldsBasedOnCmpVersion = (fields, valuesToSend) => {
  fields.forEach((field) => {
    if (!isFieldEnabled(field, valuesToSend.cmpVersion)) {
      delete valuesToSend[field];
    }
  });
};

const SitesFormsPage = ({
  onSubmit,
  themes,
  sites,
  versions,
  versionsV2,
  initData,
  title,
  toolTipTitle,
  pending,
  fromSiteDetail,
  path,
  isPremium,
  dataTest,
  pcode,
  history,
  vendors,
}: Props) => {
  const [form] = Form.useForm();

  const [hasChanges, setHasChanges] = useState();
  const [values, setValues] = useState({});
  const [generalFormError, setGeneralFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(GENERAL_FORM_ERROR);

  const siteThemes = themes.filter(theme => theme.themeType === 'SITE' || theme.defaultTheme);

  const goBack = () => {
    if (fromSiteDetail) {
      history.goBack();
    } else {
      history.push(`/protect/p-${pcode}/sites`);
    }
  };

  const handleSubmit = (e) => {
    form.validateFields().then((formValues) => {
      if (formValues && formValues.consentOrPayConfig && formValues.consentOrPayConfig.enabled) {
        const eeaCountries = formValues.consentOrPayConfig && 
        formValues.consentOrPayConfig.countries &&
        formValues.consentOrPayConfig.countries.length > 0 ?  
        formValues.consentOrPayConfig.countries.filter(country => country === 'eea'): [];
        if (eeaCountries.length > 0) {
          formValues.consentOrPayConfig.countries = EEA_AND_UK.map(country => country3(country));
        }
      }
      const valuesToSend = {
        ...initData,
        ...values,
        ...formValues,
        uspLspact: formValues.uspLspact ? 'Y' : 'N',
        uspJurisdiction: formValues.uspJurisdiction ? ['US'] : ['CA'],
        thirdPartyStorageType: formValues.thirdPartyStorageType || (values && values.thirdPartyStorageType),
        repromtDaysAfterGvlUpdate: Number(formValues.repromtDaysAfterGvlUpdate),
        // Somehow nested values are not being set correctly in the form, So getting them from the values state
        gbcApplicablePurposes: values.gbcApplicablePurposes,
        gdprPrivacyLink: formValues.gdprPrivacyLink ? formValues.gdprPrivacyLink : '',
      };
      valuesToSend.purposeIdsV2 = valuesToSend.purposeIdsV2 || [];
      valuesToSend.purposeLegitimateIdsV2 = valuesToSend.purposeLegitimateIdsV2 || [];
      if (formValues.noLegalBasis) {
        valuesToSend.specialFeatures = [];
        valuesToSend.stackIds = [];
        valuesToSend.specialPurposes = [];
        valuesToSend.features = [];
      } else {
        valuesToSend.purposeIdsV2 = valuesToSend.purposeIdsV2.filter(
          p => !valuesToSend.purposeLegitimateIdsV2.includes(p)
        );
      }
      if (valuesToSend.gbcEnabled) {
        valuesToSend.gbcLocations = [valuesToSend.gbcLocations];

        const gbcPurposes = [];
        valuesToSend.gbcApplicablePurposes.forEach((purpose) => {
          if (purpose.enabled) {
            gbcPurposes.push({ id: purpose.id, defaultValue: purpose.defaultValue });
          }
        });
        valuesToSend.gbcApplicablePurposes = gbcPurposes;
      } else {
        delete valuesToSend.gbcLocations;
        delete valuesToSend.gbcApplicablePurposes;
      }

      if (!isGPPSupported(valuesToSend.cmpVersion)) {
        delete valuesToSend.gdprEncodingMode;
        delete valuesToSend.isCoveredTransaction;
        delete valuesToSend.mspaJurisdiction;
        delete valuesToSend.ccpaViaUsp;
        delete valuesToSend.mspaSensitiveDataPurposeIds;
        delete valuesToSend.mspaAutoPopUp;
        delete valuesToSend.consentLocations;
      } else if (valuesToSend.hasCCPA) {
        valuesToSend.mspaOptOutPurposeIds = [
          ...formValues.mspaPurposeIds,
          ...(formValues.mspaSensitiveDataProcessing ? [SENSITIVE_PURPOSE_ID] : []),
          ...(formValues.mspaChildSensitiveDataProcessing ? [CHILD_PURPOSE_ID] : [])
        ];
        valuesToSend.mspaSignalMode = 'OPT_OUT';
        if (!valuesToSend.mspaSensitiveDataProcessing) delete valuesToSend.mspaSensitiveDataPurposeIds;
      }
      delete valuesToSend.mspaPurposeIds;
      delete valuesToSend.mspaSensitiveDataProcessing;
      delete valuesToSend.mspaChildSensitiveDataProcessing;
      delete valuesToSend.supportsGooglePersonalization;
      delete valuesToSend.privacyMode;
      delete valuesToSend.purposeIds;
      delete valuesToSend.hasCCPA;
      delete valuesToSend.hasGDPR;
      delete valuesToSend.radioGroup;

      deleteFieldsBasedOnCmpVersion(['legitimateInterestOptIn', 'urlPassThrough',
        'adsDataRedaction'], valuesToSend);

      if (valuesToSend.publisherLogoUrl) {
        setImageSizeParameters(valuesToSend.publisherLogoUrl).then((urlParams) => {
          valuesToSend.publisherLogoUrl = `${valuesToSend.publisherLogoUrl}${urlParams}`;
          onSubmit(e, valuesToSend);
        }).catch((error) => {
          setGeneralFormError(true);
          setErrorMessage(error);
        });
      } else {
        onSubmit(e, valuesToSend);
      }
    }).catch((e) => {
      console.error(e);
      setGeneralFormError(true);
      setErrorMessage(GENERAL_FORM_ERROR);
    });
  };

  let backLabel: string = fromSiteDetail ? 'Back to sites detail' : 'Back to sites';
  let backButtonAction: string = fromSiteDetail ? path : 'sites';

  if (!fromSiteDetail) {
    backLabel = BACK_TO_ALL_PROPERTIES_LABEL;
    backButtonAction = `p-${pcode}/properties`;
  }

  return (
    <section className={Styles.wrapper} data-testid={dataTest}>
      <div className={Styles.header}>
        <ContentHeader
          title={title}
          toolTipTitle={toolTipTitle}
          backBtn={{
            goTo: backButtonAction,
            text: backLabel
          }}
          actions={[
            {
              component: Space,
              children: [
                {
                  component: Button,
                  props: {
                    type: 'secondary',
                    onClick: goBack
                  },
                  children: ['CANCEL']
                },
                {
                  component: Button,
                  props: {
                    type: 'primary',
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled: !hasChanges || (!initData.editable || pending),
                    onClick: handleSubmit,
                  },
                  children: ['SAVE PROPERTY']
                }
              ]
            }]}
        />
      </div>
      <div className={Styles.container}>
        <section className={Styles.content}>
          {!pending
            ? (
              <>
                {!initData.editable
                  && (
                    <Alert
                      type="warning"
                      message="The property has pending updates and therefore changes cannot be saved."
                    />
                  )
                }
                <SitesForm
                  onSubmit={onSubmit}
                  onChanges={value => setHasChanges(value)}
                  themes={siteThemes}
                  sites={sites}
                  versions={versions}
                  versionsV2={versionsV2}
                  initData={initData}
                  isPremium={isPremium}
                  isNew={title === 'Create a site'}
                  generalFormError={generalFormError}
                  errorMessage={errorMessage}
                  form={form}
                  setValues={setValues}
                  pCode={pcode}
                  vendors={vendors}
                />
              </>
            )
            : <Loader />
          }
        </section>
        <aside className={Styles.aside} />
      </div>
    </section>
  );
};

export default SitesFormsPage;
