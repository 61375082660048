// @flow
import React, { useEffect } from 'react';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Radio from 'antd/es/radio';
import Space from 'antd/es/space';
import PurposeList from './components/purposeList';
import Modal from '../../../../ui/modal';
import RecommendedList from '../../../../ui/recommended-list/recommendedList';
import Styles from '../../../../ui/recommended-list/purposesV2.module.scss';
import StylesForm from '../../../../../components/forms/sites/sitesForm.module.scss';
import { booleanOptions } from '../../../../../utils/constants';
import { isFieldEnabled } from '../../../../../utils/sites';
import SITETOOLTIPS from '../../../../../utils/toolTipsMessages';
import { Tooltip } from 'antd/es';
import InfoIconTooltip from '../../../../ui/infoIconTooltip';
import TEXT from '../../../general_settings_site/text';
import { Alert } from 'antd';

type Props = {
  purposeIds: Array<number>;
  purposeLegitimateIds: Array<number>;
  specialFeatures: Array<number>;
  consentScope: String;
  isDefaultConsent: Boolean;
  initData: Object;
  specialPurposes: Array<any>;
  features: Array<any>;
  getFieldValue: Function;
  setFieldsValue: Function;
  validateFields: Function;
  setValues: Function;
  setDataTestId: Function;
  themeSelected: Record<string, any>;
  vendorsData: Record<string, any>;
  propertyType: string;
  cmpVersion: String;
};

const PurposesV2 = ({
  purposeIds,
  purposeLegitimateIds,
  specialFeatures,
  consentScope,
  isDefaultConsent,
  initData,
  specialPurposes,
  features,
  getFieldValue,
  setFieldsValue,
  validateFields,
  setValues,
  setDataTestId,
  themeSelected,
  vendorsData,
  propertyType = 'site',
  cmpVersion = ''
}: Props) => {
  const publisherCC = getFieldValue('publisherCC');
  const isServiceOrServiceGrp = consentScope === 'service' || consentScope === 'service group';
  const [modifiedList, setModifiedList] = React.useState({
    publisherPurposeConsentIds: initData.purposeIdsV2,
    publisherSpecialFeaturesIds: initData.specialFeatures,
    stacks: [],
    publisherPurposeLegitimateInterestIds: initData.purposeLegitimateIdsV2,
    specialPurposes,
    features
  });
  const [purposesList, setPurposesList] = React.useState({
    purposeIdsV2: [],
    purposeLegitimateIdsV2: [],
    speciaspecialFeatures: [],
    specialFeatures: [],
    features: []
  });
  const [purposeValue, setPurposeValue] = React.useState(
    modifiedList.publisherPurposeConsentIds.length > 0 ? 'customized' : 'applyRecommended'
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  const [saveStacks, setSaveStacks] = React.useState(!isDefaultConsent);
  const [customizedChanged, setCustomizedChanged] = React.useState(initData.purposeIdsV2.length > 0);
  const [themeGVL, setThemeGVL] = React.useState(
    themeSelected && themeSelected.gvlVersion ? themeSelected.gvlVersion : -2
  );
  const thereArePurposes = () => Object.values(modifiedList).some(p => p.length > 0);

  useEffect(() => {
    if (themeSelected && themeSelected.gvlVersion && themeGVL !== themeSelected.gvlVersion) {
      setThemeGVL(themeSelected.gvlVersion);
      setModifiedList({
        publisherPurposeConsentIds: initData.purposeIdsV2,
        publisherSpecialFeaturesIds: initData.specialFeatures,
        stacks: [],
        publisherPurposeLegitimateInterestIds: initData.purposeLegitimateIdsV2,
        specialPurposes,
        features
      });
      setPurposesList({
        purposeIdsV2: [],
        purposeLegitimateIdsV2: [],
        speciaspecialFeatures: [],
        specialFeatures: [],
        features: []
      });
      setPurposeValue('applyRecommended');
      setValues(preValues => ({
        ...preValues,
        noLegalBasis: true,
        radioGroup: 'applyRecommended',
        purposeIdsV2: [],
        purposeLegitimateIdsV2: [],
        features: [],
        specialFeatures: [],
        specialPurposes: []
      }));
      setFieldsValue({ radioGroup: 'applyRecommended' });
    }
  }, [themeSelected]);

  const onChangeRadioButton = (event) => {
    const { target: { value } } = event;
    if (value === 'applyRecommended') {
      setPurposeValue('applyRecommended');
      setValues(preValues => ({
        ...preValues,
        noLegalBasis: true,
        purposeIdsV2: [],
        purposeLegitimateIdsV2: [],
        features: [],
        specialFeatures: [],
        specialPurposes: []
      }));
      validateFields(['publisherName']);
    } else {
      setPurposeValue('customized');
      setValues(preValues => ({
        ...preValues,
        noLegalBasis: false,
        purposeIdsV2: modifiedList.publisherPurposeConsentIds,
        purposeLegitimateIdsV2: modifiedList.publisherPurposeLegitimateInterestIds,
        features: modifiedList.features,
        specialFeatures: modifiedList.publisherSpecialFeaturesIds,
        specialPurposes: modifiedList.specialPurposes
      }));
      validateFields(['publisherName']);
      if (!customizedChanged) {
        setModalOpen(true);
      }
    }
  };

  const onCancel = () => {
    if (!thereArePurposes()) {
      setSaveStacks(false);
    }
    setModalOpen(false);
    const purposesType = customizedChanged ? purposeValue : 'applyRecommended';
    setPurposeValue(purposesType);
    setFieldsValue({ radioGroup: purposesType });
    setValues(preValues => ({
      ...preValues,
      noLegalBasis:
        customizedChanged ? purposeValue !== 'customized' : purposeValue === 'customized'
    }));
  };

  const onSave = () => {
    setSaveStacks(true);
    setModifiedList({
      publisherPurposeConsentIds:
        purposesList.purposeIdsV2.filter(p => !purposesList.purposeLegitimateIdsV2.includes(p)),
      publisherSpecialFeaturesIds: purposesList.specialFeatures,
      publisherPurposeLegitimateInterestIds:
        purposesList.purposeLegitimateIdsV2.filter(p => purposesList.purposeIdsV2.includes(p)),
      specialPurposes: purposesList.specialPurposes,
      stacks: [],
      features: purposesList.features
    });
    setPurposeValue('customized');
    setModalOpen(false);
    setValues(preValues => ({
      ...preValues,
      ...purposesList,
      purposeIdsV2:
        purposesList.purposeIdsV2.filter(p => !purposesList.purposeLegitimateIdsV2.includes(p)),
      purposeLegitimateIdsV2:
        purposesList.purposeLegitimateIdsV2.filter(p => purposesList.purposeIdsV2.includes(p)),
      noLegalBasis: false
    }));
    setCustomizedChanged(true);
  };

  const footer = () => (
    <Space>
      <Button
        className="cancelBttn"
        type="secondary"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        className="OKButton"
        type="primary"
        onClick={onSave}
      >
        OK
      </Button>
    </Space>
  );

  const customizedText = <p>Customised purposes configuration:</p>;
  const content = <RecommendedList list={[modifiedList]} purposesData={vendorsData.data} />;

  return (
    <>
      {!isServiceOrServiceGrp && (
        <p className="serviceGroup">
          Note: Please use <i>service</i> or <i>service group</i> scopes to declare a legal basis.
        </p>
      )}
      <Form.Item
        name="radioGroup"
        data-testid="legalBasisRB"
        label="Do you want to declare legal basis for your property?"
      >
        <Radio.Group className={Styles.customRadio} onChange={onChangeRadioButton}>
          <Space direction="vertical">
            <Radio value="applyRecommended" data-testid={setDataTestId('applyRecommended')}>
              {"I don't declare a legal basis for my property"}
            </Radio>
            <Space>
              <Radio value="customized" data-testid={setDataTestId('customized')} disabled={!isServiceOrServiceGrp}>
                I define my legal basis
              </Radio>
              <Button
                type="link"
                disabled={purposeValue === 'applyRecommended'}
                className="customizeSettingsBttn"
                onClick={() => setModalOpen(true)}
              >
                CUSTOMIZE SETTINGS
              </Button>
            </Space>
          </Space>
        </Radio.Group>
      </Form.Item>
      {purposeValue === 'customized' && saveStacks && customizedText}
      {purposeValue === 'customized' && content}

      {propertyType === 'site' && isFieldEnabled('legitimateInterestOptIn', cmpVersion) && (
        <Form.Item
          name="legitimateInterestOptIn"
          label={
            <Space direction="vertical">
              <span>Should legitimate interests be enabled by default?</span>
              <span className={Styles.subTitle}>
                Selecting Yes to this would imply that legitimate interests would be opted-in by default and users can
                disable them individually.
              </span>
            </Space>
          }
        >
          <Radio.Group options={booleanOptions} />
        </Form.Item>
      )}

      {propertyType === 'site' && isFieldEnabled('includeVendorsDisclosedSegment', cmpVersion) && (
        <Form.Item
          name="includeVendorsDisclosedSegment"
          label={
            <Space direction="horizontal">
              <span>DO YOU WANT TO INCLUDE "VENDORS DISCLOSED" AS PART OF TCF STRING?</span>
              <Tooltip title={SITETOOLTIPS.vendorsDisclosed} placement="top">
                <span>
                  <InfoIconTooltip />
                </span>
              </Tooltip>
            </Space>
          }
        >
          <Radio.Group options={booleanOptions} />
        </Form.Item>
      )}

      {isFieldEnabled('gdprEnabledInUS', cmpVersion) && (
        <Form.Item
          name="gdprEnabledInUS"
          help={TEXT.GDPR_IN_USA_WARNING}
          label={
            <Space direction="horizontal">
              <span style={{ textTransform: 'uppercase' }}>
                Do you wish to enable GDPR compliance for users in the US region?
              </span>
            </Space>
          }
        >
          <Radio.Group options={booleanOptions} />
        </Form.Item>
      )}

      {propertyType === 'app' && (
        <Form.Item
          name="gdprEnabledInUS"
          help={TEXT.GDPR_IN_USA_WARNING}
          label={
            <Space direction="horizontal">
              <span style={{ textTransform: 'uppercase' }}>
                Do you wish to enable GDPR compliance for users in the US region?
              </span>
            </Space>
          }
        >
          <Radio.Group options={booleanOptions} />
        </Form.Item>
      )}

      <Modal
        title="Customize Purposes"
        onClose={onCancel}
        onCancel={onCancel}
        open={modalOpen}
        footer={footer()}
        closable={false}
        destroyOnClose
        className={Styles.modalSize}
      >
        <PurposeList
          purposeIds={purposeIds}
          purposeLegitimateIds={purposeLegitimateIds}
          specialFeatures={specialFeatures}
          publisherCC={publisherCC}
          specialPurposes={specialPurposes}
          features={features}
          setFieldsValue={setFieldsValue}
          validateFields={validateFields}
          getFieldValue={getFieldValue}
          setValues={setPurposesList}
          setDataTestId={setDataTestId}
          vendorsData={vendorsData && vendorsData.data}
        />
      </Modal>
    </>
  );
};

export default PurposesV2;
