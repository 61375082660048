// @flow
import React, { useState } from 'react';
import Card from 'antd/es/card';
import uid from 'uid';
import { Select, Tooltip } from 'antd/es';
import Table from '../../../../components/ui/table';
import ReportGDPRToolTip from '../../../../components/ui/report-gdpr';
import Styles from './choiceReports.module.scss';

type Props = {
  analytics: Object;
  privacyMode: Array;
  dataTestid: String;
  isGBCEnabled: Boolean;
  isGPPEnabled: Boolean;
}

const defaultData = {
  totalShown: '0',
  acceptAll: '0',
  rejectAll: '0',
  partialAll: '0'
};

const stateMap = {
  ca: 'california',
  va: 'virginia',
  co: 'colorado',
  ct: 'connecticut',
  ut: 'utah',
  all: 'all'
};

const headers = {
  events: (
    <>
      EVENT
      <ReportGDPRToolTip />
    </>
  ),
  visits: 'visits',
  percent: 'percent'
};

const getLabel = regulationText => (
  <Tooltip placement="left" title={regulationText}>
    <span>{regulationText}</span>
  </Tooltip>
);

const getTableData = reportData => [
  {
    key: '1',
    events: 'Saw InMobi CMP',
    visits: reportData.totalShown.toLocaleString(),
    percent: '100.00 %'
  },
  {
    key: '2',
    events: 'Accept All',
    visits: reportData.acceptAll.toLocaleString(),
    percent: reportData.acceptedRatePercentage
      ? `${reportData.acceptedRatePercentage.toFixed(2)}%` : '0.00%'
  },
  {
    key: '3',
    events: 'Reject All',
    visits: reportData.rejectAll.toLocaleString(),
    percent: reportData.rejectedRatePercentage
      ? `${reportData.rejectedRatePercentage.toFixed(2)}%` : '0.00%'
  },
  {
    key: '4',
    events: 'Accept Some',
    visits: reportData.partialAll.toLocaleString(),
    percent: reportData.partialRatePercentage
      ? `${reportData.partialRatePercentage.toFixed(2)}%` : '0.00%'
  },
  {
    key: '5',
    events: 'Bounce',
    visits: reportData.bounce ? reportData.bounce.toLocaleString() : 0,
    percent: reportData.bounceRatePercentage
      ? `${reportData.bounceRatePercentage.toFixed(2)}%` : '0.00%'
  }
];

const getDataOrDefault = (data, key) => (data[key] && data[key].length > 0 ? data[key][0] : defaultData);

const getUSDataOrDefault = (data) => {
  const uspData = {
    california: defaultData,
    virginia: defaultData,
    colorado: defaultData,
    connecticut: defaultData,
    utah: defaultData,
    national: defaultData,
    all: defaultData
  };
  data['3'].forEach((item) => {
    if (item.state === null) {
      uspData.national = item;
    } else {
      const state = stateMap[item.state];
      uspData[state] = item;
    }
  });
  return uspData;
};

const getUSDataByRegulation = (uspData, regulation) => {
  const reg = regulation.toLowerCase();
  const state = stateMap[reg];
  return uspData[state] || uspData.national;
};

const getAnalyticsData = (data, regulation) => {
  if (!data) {
    return defaultData;
  }

  const gdprData = getDataOrDefault(data, '1');
  const gbcData = getDataOrDefault(data, '10');
  const ccpaData = getDataOrDefault(data, '2');
  const uspData = getUSDataOrDefault(data);

  switch (regulation) {
    case 'gdpr':
      return gdprData;
    case 'ccpa':
      return ccpaData;
    case 'gbc':
      return gbcData;
    default:
      return getUSDataByRegulation(uspData, regulation);
  }
};

const getRegulationOptions = (privacyMode, isGBCEnabled, isGPPEnabled) => {
  const options = [];
  if (privacyMode && privacyMode.includes('GDPR')) {
    options.push({ value: 'gdpr', label: 'European Regulation' });
  }
  if (privacyMode && privacyMode.includes('USP')) {
    const uspOptions = [{ value: 'ccpa', label: getLabel('US Regulation CCPA') }];
    if (isGPPEnabled) {
      uspOptions.push(...[
        { value: 'all', label: getLabel('US Regulation - All (MSPA)') },
        { value: 'ca', label: getLabel('US Regulation - California (MSPA)') },
        { value: 'va', label: getLabel('US Regulation Virginia - (MSPA)') },
        { value: 'co', label: getLabel('US Regulation Colorado - (MSPA)') },
        { value: 'ct', label: getLabel('US Regulation Connecticut - (MSPA)') },
        { value: 'ut', label: getLabel('US Regulation Utah - (MSPA)') },
        { value: 'national', label: getLabel('US Regulation National - (MSPA)') },
      ]);
    }
    options.push(...uspOptions);
  }
  // Comment out GBC for now
  // if (isGBCEnabled) {
  //   options.push({ value: 'gbc', label: 'GBC' });
  // }
  return options;
};

const ChoiceReport = ({
  analytics,
  privacyMode,
  dataTestid,
  isGBCEnabled,
  isGPPEnabled
}: Props) => {
  const data = analytics || null;
  const [regulation, setRegulation] = useState('gdpr');
  const currentData = getAnalyticsData(data, regulation);

  const handleRegulationChange = (value) => {
    setRegulation(value);
  };

  return (
    <div className={Styles.container} data-flex data-layout="column" data-testid={dataTestid}>
      <p><strong>Report period: Last 30 days</strong></p>
      <Card
        title={[
          <span key={uid()}>Reports</span>,
          <span className={Styles.cardSubtitle} key={uid()}>
            How visitors are interacting with your consent solution on this site.
          </span>
        ]}
        extra={(
          <Select
            value={regulation}
            style={{ width: 200 }}
            onChange={handleRegulationChange}
            options={getRegulationOptions(privacyMode, isGBCEnabled, isGPPEnabled)}
            data-testid="regulation_dropdown"
          />
        )}
        className={Styles.reportCard}
        data-testid={`card_reports_${regulation}`}
      >
        <div className={Styles.content}>
          <div className={Styles.contentLeft}>
            <h1>
              {`${currentData.totalShown.toLocaleString()} times`}
            </h1>
            <h3>InMobi CMP was shown</h3>
          </div>
          <div className={Styles.contentRight}>
            <h1>
              {`${currentData.cookiesConsentRatePercentage ? currentData.cookiesConsentRatePercentage.toFixed(2) : 0}%`}
            </h1>
            <h3>Consent rate</h3>
          </div>
        </div>
        <hr className={Styles.separatorLine} data-testid="hr_GDPR" />
        <div className={Styles.tableContainer}>
          <h3 className={Styles.title}>Consent details</h3>
          <Table
            data={getTableData(currentData)}
            headers={headers}
          />
        </div>
      </Card>
    </div>
  );
};
export default ChoiceReport;
