import { Col, Row } from 'antd/es/grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'antd/es/form';
import { useHistory } from 'react-router-dom';
import {
  CHILD_PURPOSE_ID,
  EEA,
  EEA_AND_UK,
  GDPR,
  GENERAL_FORM_ERROR,
  GVL_VERSION_2,
  GVL_VERSION_3,
  SENSITIVE_PURPOSE_ID,
  XSS_ERROR_TEXT,
  USA,
  USP,
  WORLDWIDE,
} from '../../../../utils/constants';
// @flow
import { createApp, editApp } from '../../../../store/duck/apps';

import Alert from '../../../../components/ui/alert';
import ConsentConfiguration from '../consent-configuration';
import ConsentScope from '../consent-scope';
import FormHeader from '../form-header';
import FormStyles from '../../../../components/forms/sites/sitesForm.module.scss';
import GeneralSettings from '../general-settings';
import GooglePartners from '../../../../components/ui/google-partners';
import Styles from '../../../sites/components/sites-form-page/sitesFormPage.module.scss';
import { fetchCMPVersionsV2 } from '../../../../store/duck/versionsV2';
import { fetchAllVendors } from '../../../../store/duck/vendors';
import { fetchAllThemes } from '../../../../store/duck/themes';
import { getGBCInitData } from '../../../../components/forms/gbc/utils';
import { setImageSizeParameters } from '../../../../utils/setImageSize';
import validations from '../../validations';
import useGBCPurposes from '../../../../components/forms/gbc/useGBCPurposes';
import Loader from '../../../../components/ui/loader';
import MSPARegulationSettings from '../../../../components/forms/mspa_settings';
import ConsentScopeUSP from '../../../../components/forms/consent_scope_usp';
import { getConsentLocation, getMSPAInitData, getIsNonPersonalisedAdPresent } from '../../../../utils/property';
import { country3, validateUserInput } from '../../../../utils/sites';
import { getConsentOrPayInitData } from '../../../../components/forms/consent_or_pay/utils';

type Props = {
  current: Object,
  titlePage: string,
  isNew: Boolean,
};

function AppForm({ current, titlePage, isNew }: Props) {
  const gbcPurposesData = useGBCPurposes();
  const isCurrentObjectEmpty = !isNew && Object.keys(current).length === 0;
  const formInitDataLoading = gbcPurposesData.length === 0 || isCurrentObjectEmpty;

  const initDataRegulations = () => {
    const regulations = [];
    const { consentLocations, privacyModes, requireConsentFrom } = current;

    const consentLocation = getConsentLocation(consentLocations, privacyModes, requireConsentFrom);
    if (consentLocation.includes(WORLDWIDE) || (consentLocation.includes(EEA) && consentLocation.includes(USA))) {
      regulations.push(...[GDPR, USP]);
    } else if (consentLocation.includes(EEA)) {
      regulations.push(GDPR);
    } else if (consentLocation.includes(USA)) {
      regulations.push(USP);
    } else {
      regulations.push(GDPR);
    }
    return regulations;
  };

  const INIT_DATA = useMemo(
    () => ({
      packageId: current.packageId || '',
      publisherName: current.publisherName || '',
      publisherLogoUrl: current.publisherLogoUrl || '',
      hasGDPR: initDataRegulations().includes('GDPR'),
      hasCCPA: initDataRegulations().includes('USP'),
      cmpVersion: current.cmpVersion || 'latest',
      publisherCC: current.publisherCC || 'GB',
      uspLspact: current.uspLspact === 'Y',
      uspJurisdiction: current.uspJurisdiction ? current.uspJurisdiction.includes('US') : false,
      requireConsentFrom: current.requireConsentFrom || 'always',
      consentScope: current.consentScope || 'service',
      consentScopeGroupURL: current.consentScopeGroupURL || '',
      thirdPartyStorageType: current.thirdPartyStorageType || 'iframe',
      redirectUrl: current.redirectUrl || '',
      repromtDaysAfterGvlUpdate: current.repromtDaysAfterGvlUpdate || 30,
      isDefaultConsent: current.isDefaultConsent || true,
      noLegalBasis: current.noLegalBasis || true,
      purposeIdsV2: current.purposeIds || [],
      purposeLegitimateIdsV2: current.purposeLegitimateIds || [],
      specialFeatures: current.specialFeatures || [],
      stackIds: current.stackIds || [],
      specialPurposes: current.specialPurposes || [],
      features: current.features || [],
      propertyType: current.propertyType || 'APP',
      googleEnabled: current.googleEnabled || false,
      gdprEnabledInUS: current.gdprEnabledInUS || false,
      themeId: current.themeId || -3,
      ...getGBCInitData(current, gbcPurposesData),
      ...getMSPAInitData(current),
      ...getConsentOrPayInitData(current),
    }),
    [current, gbcPurposesData],
  );

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    apps,
    cmpVersionV2,
    app: { pCode },
    sites,
    themes,
  } = useSelector(state => state);
  const [values, setValues] = useState(() => {
    const extras = {};
    extras.purposeIdsV2 = INIT_DATA.purposeIdsV2.length ? INIT_DATA.purposeIds : [];

    if (INIT_DATA.purposeLegitimateIdsV2.length) {
      extras.legitimatePurposes = INIT_DATA.purposeLegitimateIdsV2;
    }

    return { ...INIT_DATA, ...extras };
  });
  const [hasChangedValues, setHasChangedValues] = useState(false);
  const [generalError, setGeneralFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(GENERAL_FORM_ERROR);
  const { setFieldsValue, validateFields, getFieldValue } = form;
  const validateForm = validations();

  const onValuesChange = useCallback(() => {
    if (!hasChangedValues) {
      setHasChangedValues(true);
    }
  }, [hasChangedValues]);

  useEffect(() => {
    if (!cmpVersionV2.list.length && !cmpVersionV2.pending) {
      dispatch(fetchCMPVersionsV2(pCode));
    }

    if (cmpVersionV2.list.length) {
      setValues({
        ...values,
        cmpVersion: cmpVersionV2.list[0],
      });
    }
  }, [cmpVersionV2]);

  useEffect(() => {
    dispatch(fetchAllVendors(pCode, GVL_VERSION_2));
    dispatch(fetchAllVendors(pCode, GVL_VERSION_3));
  }, [pCode]);
  
  useEffect(() => {
    setValues({ ...INIT_DATA });
  }, [current, gbcPurposesData]);

  const appDataTest = input => `app-${input}-input`;

  const sendInfoForm = valuesToSend => {
    if (Object.keys(current).length) {
      dispatch(editApp(current.siteId, valuesToSend));
    } else {
      dispatch(createApp(valuesToSend));
    }
  };

  const handleSubmit = () => {
    validateFields()
      .then(formValues => {
        if (formValues && formValues.consentOrPayConfig && formValues.consentOrPayConfig.enabled) {
          const eeaCountries = formValues.consentOrPayConfig.countries.filter(country => country === 'eea');
          if (eeaCountries.length > 0) {
            formValues.consentOrPayConfig.countries = EEA_AND_UK.map(country => country3(country));
          }
        }
        const valuesToSend = {
          ...values,
          ...formValues,
          pCode,
          majorVersion: '2',
          purposeIds: values.purposeIdsV2 ? values.purposeIdsV2 : [],
          specialFeatures: values.specialFeatures,
          specialPurposes: values.specialPurposes,
          features: values.features,
          purposeLegitimateIds: values.purposeLegitimateIdsV2 ? values.purposeLegitimateIdsV2 : [],
          repromtDaysAfterGvlUpdate: Number(formValues.repromtDaysAfterGvlUpdate),
          gbcApplicablePurposes: values.gbcApplicablePurposes,
        };
        if (valuesToSend.gbcEnabled) {
          valuesToSend.gbcLocations = [valuesToSend.gbcLocations];

          const gbcPurposes = [];
          valuesToSend.gbcApplicablePurposes.forEach(purpose => {
            if (purpose.enabled) {
              gbcPurposes.push({ id: purpose.id, defaultValue: purpose.defaultValue });
            }
          });
          valuesToSend.gbcApplicablePurposes = gbcPurposes;
        } else {
          delete valuesToSend.gbcLocations;
          delete valuesToSend.gbcApplicablePurposes;
        }

        if (values.consentOrPayConfig && values.consentOrPayConfig.enabled) {
          valuesToSend.consentOrPayConfig.callbackType = values.consentOrPayConfig.callbackType || 'self';
        }
        valuesToSend.uspJurisdiction = valuesToSend.uspJurisdiction ? ['US'] : ['CA'];
        valuesToSend.uspLspact = valuesToSend.uspLspact ? 'Y' : 'N';

        if (values.noLegalBasis) {
          valuesToSend.purposeIds = [];
          valuesToSend.purposeLegitimateIds = [];
          valuesToSend.features = [];
          valuesToSend.specialFeatures = [];
          valuesToSend.specialPurposes = [];
        }

        if (valuesToSend.hasCCPA) {
          valuesToSend.mspaOptOutPurposeIds = [
            ...(formValues.mspaPurposeIds || []),
            ...(formValues.mspaSensitiveDataProcessing ? [SENSITIVE_PURPOSE_ID] : []),
            ...(formValues.mspaChildSensitiveDataProcessing ? [CHILD_PURPOSE_ID] : []),
          ];
          valuesToSend.mspaSignalMode = 'OPT_OUT';
          if (!valuesToSend.mspaSensitiveDataProcessing) delete valuesToSend.mspaSensitiveDataPurposeIds;
        }
        delete valuesToSend.mspaPurposeIds;
        delete valuesToSend.mspaSensitiveDataProcessing;
        delete valuesToSend.mspaChildSensitiveDataProcessing;

        valuesToSend.purposeIds.forEach(p => delete valuesToSend[`legitime.${p}`]);
        delete valuesToSend.hasGDPR;
        delete valuesToSend.hasCCPA;
        delete valuesToSend.publisherConsentRestrictionIds;
        delete valuesToSend.publisherLIRestrictionIds;
        delete valuesToSend.purposeIdsV2;
        delete valuesToSend.purposeLegitimateIdsV2;
        delete valuesToSend.radioGroup;
        delete valuesToSend.logoUrlSize;

        // Validate the html input for XSS
        const areValidInput = validateUserInput({ uspDnsText: valuesToSend.uspDnsText });
        if (!areValidInput) {
          throw new Error('XSS_ERROR');
        }

        if (formValues.publisherLogoUrl) {
          setImageSizeParameters(formValues.publisherLogoUrl, true)
            .then(logoUrlSize => {
              valuesToSend.publisherLogoUrl = `${formValues.publisherLogoUrl}${logoUrlSize}`;
              sendInfoForm(valuesToSend);
            })
            .catch(error => {
              setGeneralFormError(true);
              setErrorMessage(error);
            });
        } else {
          sendInfoForm(valuesToSend);
        }
      })
      .catch(err => {
        console.error(err);
        setGeneralFormError(true);
        if (err.message === 'XSS_ERROR') {
          setErrorMessage(XSS_ERROR_TEXT);
        }
      });
  };

  const changeRegulations = regulations => {
    setValues(prevValues => ({
      ...prevValues,
      hasGDPR: regulations.includes('GDPR'),
      hasCCPA: regulations.includes('USP'),
    }));
  };

  const onChangePublisherCC = value => {
    // if the publisherCC is set to an EEA or UK force requireConsentFrom to 'always'
    if (EEA_AND_UK.includes(value)) {
      setFieldsValue({ requireConsentFrom: 'always' });
    }
  };

  const initDataConsentConfiguration = () =>
    INIT_DATA.purposeIdsV2 && INIT_DATA.purposeIdsV2.length ? 'customized' : 'applyRecommended';

  const vendorsData = useSelector(state => (values.gvlVersion === GVL_VERSION_2 ? state.vendors.v2 : state.vendors.v3));

  const {
    data: { vendors: vendorsList, blockedVendors },
  } = vendorsData;
  const isNonPersonalisedAdPresent = getIsNonPersonalisedAdPresent(vendorsList, blockedVendors);

  // Enable the Save Button if the app is on an old version, So that the user can save the default values of newly added fields.
  const isAppOnOldVersion = !current.consentLocations;

  return (
    <section className={Styles.wrapper}>
      <div className={Styles.header}>
        <FormHeader
          pending={apps.pending}
          handleSubmit={handleSubmit}
          titlePage={titlePage}
          hasChanges={hasChangedValues || isAppOnOldVersion}
        />
      </div>
      {formInitDataLoading ? (
        <Loader />
      ) : (
        <div className={Styles.container}>
          <section className={Styles.content}>
            {generalError && <Alert type="error" message={errorMessage} />}
            <Form
              name="app-form"
              form={form}
              className={FormStyles.cardsWrapper}
              layout="vertical"
              initialValues={{
                ...INIT_DATA,
                privacyModes: initDataRegulations(),
                purposeLegitimateIdsV2: [],
                purposeIdsV2: [],
                specialPurposes: [],
                specialFeatures: [],
                radioGroup: initDataConsentConfiguration(),
              }}
              onValuesChange={onValuesChange}
            >
              <Row gutter={[16, 16]}>
                {apps.error.status !== 200 && (
                  <Col span="24">
                    <Alert
                      message="There was an error creating your app"
                      type="error"
                      description={apps.error.message || 'There was an unexpected error, please try again later'}
                    />
                  </Col>
                )}
                <Col span="24">
                  <GeneralSettings
                    dataTest={appDataTest}
                    hasGDPR={values.hasGDPR}
                    hasCCPA={values.hasCCPA}
                    hasLegalBasis={!values.noLegalBasis}
                    changeRegulations={changeRegulations}
                    onChangePublisherCC={onChangePublisherCC}
                    setValues={setValues}
                    validations={validateForm}
                    sites={sites.list}
                    isNew={isNew}
                    validateFields={validateFields}
                    setFieldsValue={setFieldsValue}
                    getFieldValue={getFieldValue}
                    values={values}
                    isNonPersonalisedAdPresent={isNonPersonalisedAdPresent}
                    onValuesChange={onValuesChange}
                    themes={themes.list}
                  />
                </Col>

                {values.hasGDPR && (
                  <Col span="24">
                    <ConsentScope
                      setDataTestId={appDataTest}
                      consentScopeIsGroup={values.consentScope === 'service group'}
                      getFieldValue={getFieldValue}
                    />
                  </Col>
                )}

                {values.hasGDPR && (
                  <Col span="24">
                    <ConsentConfiguration
                      values={values}
                      initData={INIT_DATA}
                      purposes={values.purposeIdsV2}
                      legitimatePurposes={values.purposeLegitimateIdsV2}
                      setFieldsValue={setFieldsValue}
                      validateFields={validateFields}
                      validatePurposes={[]}
                      setValues={setValues}
                      getFieldValue={getFieldValue}
                      setDataTestId={appDataTest}
                      vendorsData={vendorsData}
                    />
                  </Col>
                )}

                {values.hasGDPR && (
                  <Col span="24">
                    <GooglePartners appDataTest={appDataTest} pcode={pCode} />
                  </Col>
                )}

                {values.hasCCPA && (
                  <>
                    <Col span="24">
                      <MSPARegulationSettings
                        className={Styles.addTopMargin}
                        setFieldsValue={form.setFieldsValue}
                        getFieldValue={form.getFieldValue}
                      />
                    </Col>
                    <Col span="24">
                      <ConsentScopeUSP
                        className={Styles.addTopMargin}
                        setFieldsValue={form.setFieldsValue}
                        getFieldValue={form.getFieldValue}
                        validateFields={form.validateFields}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </section>
          <aside className={Styles.aside} />
        </div>
      )}
    </section>
  );
}

export default AppForm;
