import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Select, Row, Card, Tabs, Radio, Space } from 'antd';
import classNames from 'classnames';
import { DISABLED_COP_LANGUAGES, getTranslationsForLang, SUPPORTED_LANGUAGES } from '../../../../utils/language';
import RichText from '../../../ui/forms/rich-text';
import SubscribeImg from '../../../../assets/images/theme-subscribe.png';
import SubscribeAndLoginImg from '../../../../assets/images/theme-subscribe-login.png';
import Styles from '../themesForm.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/fontawesome-pro-solid';
import { getDefaultLangs, getLanguageLabel, getPrivacyBodyForConsentOrPay } from '../utils';
import CustomLinks from './custom-links';

const ConsentOrPayConfig = ({ setFieldsValue, getFieldValue, onValuesChange, validations }) => {
  const currentSettings = getFieldValue('consentOrPaySettings');
  const currentLayout = getFieldValue('consentOrPayLayout');
  const initialLanguage = currentSettings && currentSettings.length > 0 ? currentSettings[0].language : 'en';
  const supportedLanguages = SUPPORTED_LANGUAGES.filter(lang => !DISABLED_COP_LANGUAGES.includes(lang.value));

  const [tabId, setTabId] = useState(initialLanguage);
  const [layout, setLayout] = useState(currentLayout || 'Subscribe');
  const [languages, setLanguages] = useState(getDefaultLangs(currentSettings));

  const privacyBodyChange = (value, lang) => {
    onValuesChange();
    const currentValues = getFieldValue('consentOrPaySettings');

    const updatedValues = currentValues.map(item => {
      if (item.language === lang) {
        return { ...item, privacyBody: value };
      }
      return item;
    });
    setFieldsValue({ consentOrPaySettings: updatedValues });
  };

  const tabItems = languages.map((lang, index) => {
    const translations = getTranslationsForLang(lang);

    return {
      key: lang,
      label: getLanguageLabel(lang),
      forceRender: true,
      children: (
        <Col span="24">
          <Form.Item noStyle name={['consentOrPaySettings', index, 'language']}></Form.Item>

          <Row gutter={16}>
            <Col span="12">
              <Form.Item label="button text to accept policy">
                <Input
                  disabled
                  placeholder={translations.consentOrPaySettings ? translations.consentOrPaySettings.accept : ''}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name={['consentOrPaySettings', index, 'viewPreferencesLabel']}
                label="button text to view/change preferences"
                rules={validations.textInput}
              >
                <Input
                  placeholder={
                    translations.consentOrPaySettings ? translations.consentOrPaySettings.viewPreferencesLabel : ''
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span="12">
              <Form.Item
                label="button text to enable subscribe"
                name={['consentOrPaySettings', index, 'action1Label']}
                rules={validations.textInput}
              >
                <Input
                  placeholder={translations.consentOrPaySettings ? translations.consentOrPaySettings.subscribe : ''}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name={['consentOrPaySettings', index, 'action2Label']}
                label="button text to enable Login"
                rules={validations.textInput}
              >
                <Input
                  placeholder={translations.consentOrPaySettings ? translations.consentOrPaySettings.login : ''}
                  disabled={layout === 'Subscribe'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form.Item label="Privacy Header" name={['consentOrPaySettings', index, 'privacyHeader']}>
                <Input
                  maxLength={24}
                  placeholder={translations.mockPurposesCopies.headline || '"We value your privacy"'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              {
                <RichText
                  label="Privacy Body Copy"
                  input={{
                    name: `privacyBody-${lang}`,
                    value: getPrivacyBodyForConsentOrPay(lang, currentSettings),
                    onChange: value => {
                      privacyBodyChange(value, lang);
                    },
                  }}
                  editorClassName="input-uspDnsText"
                  className="uspDnsText-container"
                  placeholder={getPrivacyBodyForConsentOrPay(lang, currentSettings)}
                />
              }
            </Col>
          </Row>
          <Form.Item noStyle name={['consentOrPaySettings', index, 'privacyBody']}></Form.Item>

          <CustomLinks
            lang={lang}
            index={index}
            validations={validations}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            fieldName={'consentOrPaySettings'}
          />
        </Col>
      ),
    };
  });

  const handleTabChange = id => {
    setTabId(id);
  };

  const handleLanguageChange = value => {
    const currentValues = getFieldValue('consentOrPaySettings');
    const added = value.find(item => !languages.includes(item));
    const removed = languages.find(item => !value.includes(item));
    if (added) {
      setFieldsValue({
        consentOrPaySettings: [
          ...currentValues,
          {
            language: added,
            privacyBody: getPrivacyBodyForConsentOrPay(added, currentValues),
          },
        ],
      });
    }
    if (removed) {
      const updatedValues = currentValues.filter(item => item.language !== removed);
      setFieldsValue({ consentOrPaySettings: updatedValues });
      if (tabId === removed) {
        setTabId(value[0]);
      }
    }
    setLanguages(value);
  };

  return (
    <div className={Styles.consentOrPay}>
      <p style={{ marginBottom: '20px' }}>
        This will be applied for premium properties, where consent or pay is enabled
      </p>
      <Card title="Choose Language" extra="GDPR">
        <Form.Item name="consentOrPayLanguage" label="Language" required>
          <Select
            showSearch
            mode="multiple"
            onChange={handleLanguageChange}
            defaultValue={languages}
            options={supportedLanguages}
            optionFilterProp="label"
            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
      </Card>
      <Card title="Customise Content" extra="GDPR">
        <Row>
          <Col span="24">
            <Form.Item className={Styles.layoutContainer} name="consentOrPayLayout" label="Choose Layout">
              <Radio.Group className={Styles.RadioGroup} value={layout} onChange={e => setLayout(e.target.value)}>
                <Radio
                  className={classNames([Styles.Radio], { [Styles.RadioSelected]: layout && layout === 'Subscribe' })}
                  value="Subscribe"
                >
                  <Space direction="vertical">
                    <div>
                      <img src={SubscribeImg} alt="Preview" />
                    </div>
                    <p>Subscribe</p>
                  </Space>
                </Radio>
                <Radio
                  className={classNames([Styles.Radio], {
                    [Styles.RadioSelected]: layout && layout === 'SubscribeAndLogin',
                  })}
                  value="SubscribeAndLogin"
                >
                  <Space direction="vertical">
                    <div>
                      <img src={SubscribeAndLoginImg} alt="Preview" />
                    </div>
                    <p>Subscribe & Login</p>
                  </Space>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Tabs
          size="small"
          type="card"
          onTabClick={handleTabChange}
          defaultActiveKey={tabId}
          items={tabItems}
          activeKey={tabId}
        />
      </Card>
    </div>
  );
};

export default ConsentOrPayConfig;
