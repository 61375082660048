import { CALLBACK_REDIRECT_METHOD } from "./constants";

export const getConsentOrPayInitData = (current) => {
  const currentSettings = current.consentOrPayConfig || {};

  const { enabled, countries, callbackType, callbacks } = currentSettings;

  return {
    consentOrPayConfig: {
      enabled: enabled || false,
      countries: countries && countries.length > 0 ? countries : [],
      callbackType: callbackType || 'publisher',
      partialAcceptEnabled: true,
      callbacks: callbacks || { action1: '', action2: '' },
      callbackRedirectMethod: CALLBACK_REDIRECT_METHOD.SAME_TAB,
    }
  };
};
