
import Card from 'antd/es/card';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import React, { useState } from 'react';
import Space from 'antd/es/space';
import Tooltip from 'antd/es/tooltip';
import uid from 'uid';
import FormStyles from '../../../../scss/_forms.module.scss';
import GBCForm from '../../../../components/forms/gbc';
import InfoIconTooltip from '../../../../components/ui/infoIconTooltip';
// @flow
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';
import Select from '../../../../components/ui/select';
import Styles from '../../../../components/forms/sites/sitesForm.module.scss';
import {
  APP_ALREADY_EXIST,
  COUNTRY_CODES,
  EEA,
  FIELD_REQUIRED_TEXT,
  GDPR,
  USA,
  USP,
  WORLDWIDE
} from '../../../../utils/constants';
import useGBCPurposes from '../../../../components/forms/gbc/useGBCPurposes';
import { getConsentLocationOptions, getRegulationOptions } from '../../../../utils/property';
import Alert from '../../../../components/ui/alert';
import TEXT from './text';
import ConsentOrPay from '../../../../components/forms/consent_or_pay/index';

type Props = {
  dataTest: Function;
  hasCCPA: boolean;
  hasGDPR: boolean;
  hasLegalBasis: boolean;
  changeRegulations: Function;
  onChangePublisherCC: Function;
  setValues: Function;
  validations: Object;
  sites: Array;
  themes: Array;
  isNew: Boolean;
  setFieldsValue: Function;
  validateFields: Function;
  values: Object;
  isNonPersonalisedAdPresent: Boolean;
  onValuesChange: Function;
  getFieldValue: Function;
};

function GeneralSettings({
  dataTest,
  hasCCPA,
  hasGDPR,
  hasLegalBasis,
  changeRegulations,
  onChangePublisherCC,
  setValues,
  validations,
  sites,
  isNew,
  setFieldsValue,
  getFieldValue,
  validateFields,
  values,
  isNonPersonalisedAdPresent,
  onValuesChange,
  themes
}: Props) {
  const { Option } = Select;
  console.log('themes', themes);
  const gbcPurposesData = useGBCPurposes();
  const [consentLocations, setConsentLocations] = useState(getFieldValue('consentLocations') || [WORLDWIDE]);

  const regulationOptions = getRegulationOptions(consentLocations);

  const consentLocationsOptions = getConsentLocationOptions(consentLocations);

  const handleConsentLocationChanged = (value) => {
    let regulation;
    if (value.includes(WORLDWIDE) || (value.includes(EEA) && value.includes(USA))) {
      regulation = [GDPR, USP];
    } else if (value.includes(EEA)) {
      regulation = [GDPR];
    } else if (value.includes(USA)) {
      regulation = [USP];
    } else {
      regulation = [GDPR];
    }
    setFieldsValue({ privacyModes: regulation });
    changeRegulations(regulation);
    setConsentLocations(value);
  };

  const handleInputChange = (input, value, newFields) => {
    const _handled = { ...newFields };
    if (value === undefined && input in _handled) {
      delete _handled[input];
    } else {
      _handled[input] = value;
    }
    return _handled;
  };

  const handleValuesChange = (inputs, vals) => {
    let newFields = { ...values };
    if (Array.isArray(inputs)) {
      inputs.forEach((input) => {
        newFields = handleInputChange(input, vals[input], newFields);
      });
    } else {
      newFields = handleInputChange(inputs, vals, newFields);
    }
    setValues(prevValues => ({ ...prevValues, ...newFields }));
  };

  return (
    <Card
      className={Styles.consentCard}
      title="General settings"
    >
      <Form.Item
        label="PACKAGE ID"
        data-testid={dataTest('packageId')}
        name="packageId"
        required
        rules={[
          {
            required: true,
            message: FIELD_REQUIRED_TEXT
          },
          {
            validator(_, value) {
              if (sites && value && isNew) {
                const existingApps = sites.filter(app => app.packageId).map(app => app.packageId.toLowerCase());
                if (existingApps.includes(value.toLowerCase())) {
                  return Promise.reject(new Error(APP_ALREADY_EXIST));
                }
              }
              return Promise.resolve();
            },
          },
          {
            pattern: /^[A-Za-z]([A-Za-z0-9_-]*\.)*[A-Za-z][A-Za-z0-9_-]*$/,
            message: 'Please enter a valid package ID'
          }
        ]}
      >
        <Input
          placeholder="App ID"
        />
      </Form.Item>
      <div className={FormStyles['wrapper-inline']}>
        <section className={`${FormStyles['inline-fields']} ${Styles.twoColumns}`}>
          <Form.Item
            label="APP NAME"
            name="publisherName"
            required={hasLegalBasis}
            rules={validations.appName(hasLegalBasis)}
          >
            <Input
              data-testid={dataTest('publisherName')}
              placeholder="App Name"
            />
          </Form.Item>
          <Form.Item
            label="LOGO URL"
            name="publisherLogoUrl"
            rules={validations.publisherLogoUrl}
          >
            <Input
              placeholder="Logo URL"
              onChange={({ target }) => setValues(preVal => ({ ...preVal, publisherLogoUrl: target.value }))}
              data-testid={dataTest('publisherLogoUrl')}
            />
          </Form.Item>
        </section>
      </div>
      <Form.Item
        name="themeId"
        label="CHOOSE A THEME"
        required
        rules={validations.themeId}
      >
        <Select
          allowClear
          onChange={themeId => setValues(preVal => ({ ...preVal, themeId }))}
        >
          {themes.map(
            theme => <Option key={theme.themeId} value={theme.themeId}>{theme.themeName}</Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        name="cmpVersion"
        label="CMP VERSION"
      >
        <Input
          disabled
        />
      </Form.Item>

      <Form.Item
        label={[<React.Fragment key={uid}>{TEXT.USER_CONSENT_LABEL}</React.Fragment>]}
        tooltip={{
          title: SITETOOLTIPS.consentLocations,
          icon: (
            <span>
              <InfoIconTooltip />
            </span>
          )
        }}
        name="consentLocations"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select
          mode="multiple"
          allowClear
          onChange={handleConsentLocationChanged}
          options={consentLocationsOptions}
        />
      </Form.Item>

      <div className={FormStyles['wrapper-inline']}>
        <Space direction="vertical" size={0}>
          <Form.Item
            label={TEXT.REGULATIONS_LABEL}
            rules={[{ required: true, message: 'Please select at least one regulation' }]}
            name="privacyModes"
            style={{ marginBottom: '10px' }}
          >
            <Checkbox.Group onChange={changeRegulations}>
              <Space direction="vertical">
                {regulationOptions.map(option => (
                  <Space size={2}>
                    <Checkbox disabled={option.disabled} value={option.value} key={option.value}>
                      {option.label}
                    </Checkbox>
                    <Tooltip title={option.tooltip} placement="right">
                      <span><InfoIconTooltip /></span>
                    </Tooltip>
                  </Space>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
          {
            hasCCPA && hasGDPR && (
              <Alert
                type="warning"
                message={TEXT.REGULATION_WARNING}
                className={Styles.warningAlert}
                showIcon
              />
            )
          }
        </Space>
      </div>

      {hasGDPR && (
        <Form.Item
          label={[
            <React.Fragment key={uid()}>
              PUBLISHER COUNTRY CODE
            </React.Fragment>
          ]}
          tooltip={{
            title: SITETOOLTIPS.countryCode,
            icon: <span><InfoIconTooltip /></span>,
          }}
          name="publisherCC"
        >
          <Select
            showSearch
            filterOption={(input, option) => (
              option.props.children.toLowerCase().includes(input.toLowerCase())
              || option.props.value.toLowerCase().includes(input.toLowerCase())
            )}
            data-testid={dataTest('publisherCC')}
            onChange={onChangePublisherCC}
          >
            {COUNTRY_CODES && COUNTRY_CODES.map(opt => (
              <Option key={opt.value} value={opt.value} disabled={opt.disabled}>{opt.label}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <GBCForm
        setFieldsValue={setFieldsValue}
        validateFields={validateFields}
        handleChange={handleValuesChange}
        values={values}
        purposesData={gbcPurposesData.slice(0, 4)}
        propertyType="app"
      />
      {
        // <ConsentOrPay
        //   getFieldValue={getFieldValue}
        //   setFieldsValue={setFieldsValue}
        //   handleChange={handleValuesChange}
        //   values={values}
        //   onValuesChange={onValuesChange}
        //   isNonPersonalisedAdPresent={isNonPersonalisedAdPresent}
        //   validateFields={validateFields}
        //   type="APP"
        // />

      }
    </Card>
  );
}

export default GeneralSettings;
