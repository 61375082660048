import { getMSPAInitData } from '../../../utils/property';
import { getConsentOrPayInitData } from '../consent_or_pay/utils';
import { getGBCInitData } from '../gbc/utils';

const formatInitData = (accountId, pCode, current, gbcPurposes) => {
  const purposeIds = [
    { id: false, legitimate: 'false' },
    { id: false, legitimate: 'false' },
    { id: false, legitimate: 'false' },
    { id: false, legitimate: 'false' },
    { id: false, legitimate: 'false' }
  ];

  const privacyPermission = 'GDPR';

  const supportsGooglePersonalization = 'supportsGooglePersonalization' in current
    && !current.supportsGooglePersonalization
    ? current.supportsGooglePersonalization
    : true;

  const defaultThemeId = '-3';

  return {
    cmpVersion: current.cmpVersion || 'latest',
    createdBy: 'inmobi',
    accountId,
    pCode,
    publisherLogoUrl: current.publisherLogoUrl || '',
    publisherName: current.publisherName || '',
    redirectUrl: current.redirectUrl || '',
    themeId: current.themeId ? `${current.themeId}` : defaultThemeId,
    url: current.url || '',
    purposeIds,
    requireConsentFrom: current.requireConsentFrom || 'always',
    consentScope: current.consentScope || 'service',
    consentScopeGroupURL: current.consentScopeGroupURL || '',
    groupOfWebsitesURL: current.groupOfWebsitesURL || '',
    repromtDaysAfterGvlUpdate: current.repromtDaysAfterGvlUpdate || 30,
    cookieAccessUrl: current.cookieAccessUrl || '',
    globalConsent: false,
    uspLspact: current.uspLspact === 'Y',
    uspJurisdiction: current.uspJurisdiction ? current.uspJurisdiction.includes('US') : false,
    privacyModes: current.privacyModes ? current.privacyModes : ['GDPR', 'USP'],
    privacyMode: current.privacyMode ? current.privacyMode : privacyPermission,
    supportsGooglePersonalization,
    thirdPartyStorageType: current.thirdPartyStorageType || 'iframe',
    purposeIdsV2: current.purposeIds || [],
    purposeLegitimateIdsV2: current.purposeLegitimateIds || [],
    stackIds: current.stackIds || [],
    specialFeatures: current.specialFeatures || [],
    specialPurposes: current.specialPurposes || [],
    features: current.features || [],
    isDefaultConsent: typeof current.isDefaultConsent === 'boolean' ? current.isDefaultConsent : true,
    publisherCC: current.publisherCC || 'GB',
    noLegalBasis: current.purposeIds ? current.purposeIds.length === 0 : true,
    googleEnabled: current.googleEnabled !== undefined ? current.googleEnabled : false,
    applyToSubDomains: current.applyToSubDomains || false,
    ampEnabled: current.ampEnabled || false,
    legitimateInterestOptIn: current.legitimateInterestOptIn !== undefined ?
      current.legitimateInterestOptIn : true,
    editable: current.editable !== undefined ? current.editable : true,
    ...getGBCInitData(current, gbcPurposes),
    ...getMSPAInitData(current),
    ...getConsentOrPayInitData(current),
    gdprPrivacyLink: current.gdprPrivacyLink || '',
    includeVendorsDisclosedSegment: current.includeVendorsDisclosedSegment || false,
    gdprEnabledInUS: current.gdprEnabledInUS || false,
  };
};

export default formatInitData;
