import React from 'react';

const TEXT = {

  CARD_TITLE: 'General Settings',
  URL_LABEL: 'Site URL',
  SUBDOMAIN_LABEL: 'Apply to all my subdomains V2',
  AMP_LABEL: 'AMP Site',
  THEME_LABEL: 'Choose a Theme',
  THEME_ALERT: (
    <p>
      <strong>CSS Overrides </strong>
      will not be considered. Initial screen will be set to
      <strong> Summary View</strong>
      {' '}
      and layout will be
      <strong> Bottom Tray Modal</strong>
      .
    </p>
  ),
  SITE_NAME_LABEL: 'Site Name',
  LOGO_LABEL: 'Site Logo',
  CMP_VERSION_LABEL: 'CMP Version',
  PUBLISHER_CC_LABEL: 'Publisher Country Code',
  USER_CONSENT_LABEL: 'WHICH USERS SHOULD BE ASKED FOR CONSENT',
  REGULATIONS_LABEL: 'Select the applicable regulations for your end-user',
  GDPR_LABEL: 'European regulation (GDPR)',
  USP_LABEL: 'US regulation',
  REGULATION_WARNING: (
    <span>
      In US jurisdictions, CCPA and other US regulations would take precedence over GDPR.
      <br />
      Therefore, only US regulation consent will be collected from users in US and GDPR
      consent will not be collected.
    </span>
  ),
  ENTIRE_US_LABEL: 'Target entire U.S.?',
  LSPA_SIGNED: 'IAB LSPA signed?',
  EEA_LABEL: 'EEA & UK',
  WORLDWIDE_LABEL: 'Worldwide',
  USA_LABEL: 'USA',
  NEVER_LABEL: 'Never',
  GDPR_IN_USA_WARNING: (
    <i>
      Note: Enabling GDPR for US users is not recommended. Activating this option will allow you to display the GDPR consent screen for US users via the applicable API.
    </i>
  )
};

export default TEXT;
