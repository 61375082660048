import { QueryFunctionContext } from "@tanstack/react-query";
import getAxiosInstance from "../axiosClient";
import type {
  iMetaData,
  iSummaryPayload,
  iSummaryAPIPayload,
  iSummaryResponse,
  iAnalyticsPayload,
  iTableDataPayload
} from "../../interfaces/analytics_v1";
import { EEA_AND_UK, REGULATION_MAP, REGULATION_TO_ID_MAP } from "../../utils/constants";
// @flow
const requestAnalytics = getAxiosInstance(process.env.REACT_APP_CHOICE_2_V1_ANALYTICS_API_URL);

const requestThemes = getAxiosInstance(process.env.REACT_APP_CHOICE_2_V2_CUSTOMIZATION_API_URL);

import { calculatePercentageChange } from "./utils";
import { PAGE_SESSIONS_STAT, REGION_SESSIONS_STAT, REGION_TRAFFIC_STAT, REGULATION_CONSENT_STAT } from "../../pages/premium-reports/constants";

async function getSummaryForDateRange(pCode: string, payload: iSummaryAPIPayload): Promise<iSummaryResponse> {
  const data = await requestAnalytics.post(`/summary/pcode/${pCode}`, { ...payload });
  return data;
}

async function getAnalyticsDataAPI(pCode: string, payload: iAnalyticsPayload): Promise<any> {
  const data = await requestAnalytics.post(`/graph/pcode/${pCode}`, { ...payload });
  return data;
}

export async function getAnalyticsMetaData(context: QueryFunctionContext): Promise<iMetaData> {
  const data = await requestAnalytics.get("/metadata");
  return data;
}

export async function getSummaryData(context: QueryFunctionContext): Promise<iAnalyticsDataList> {
  const [, queryPayload] = context.queryKey;
  const { summaryDateRange, comparisonDateRange, metricFields, propertyId, pCode }: iSummaryPayload = queryPayload;

  const getPayload = (dateRange, metricFields) => ({
    filters: [
      {
        key: "startDate",
        operator: "gteq",
        values: [dateRange[0]]
      },
      {
        key: "endDate",
        operator: "lteq",
        values: [dateRange[1]]
      },
      {
        key: "propertyId",
        operator: "in",
        values: propertyId
      }
    ],
    metrics: metricFields
  });

  const [summaryData, comparisionData] = await Promise.all([
    getSummaryForDateRange(pCode, getPayload(summaryDateRange, metricFields)),
    getSummaryForDateRange(pCode, getPayload(comparisonDateRange, metricFields))
  ]);

  const data = Object.keys(summaryData).map(key => ({
    fieldName: key,
    value: summaryData[key],
    comparisonPercentage: calculatePercentageChange(comparisionData[key], summaryData[key])
  }));

  return data;
}

export async function getAnalyticsData(context: QueryFunctionContext): Promise<iAnalyticsDataList> {
  const [, queryPayload] = context.queryKey;
  const { pCode, filters, queries }: iAnalyticsPayload = queryPayload;

  const queryPromises = queries.map(query =>
    getAnalyticsDataAPI(pCode, { filters, queries: [query] })
  );
  const data = await Promise.all(queryPromises);
  const metricsData = data.map((item, index) => {
    return item[0]
  });

  const processedData = [];
  const processMetric = {
    [REGION_SESSIONS_STAT](metric) {
      const resultsObj = metric.results.reduce((acc, curr) => {
        const { period, country, totalSessions } = curr;
        if (!acc[period]) {
          acc[period] = { totalSessions: 0, europeSessions: 0, usSessions: 0 };
        }
        acc[period].totalSessions += totalSessions;
        if (EEA_AND_UK.includes(country)) acc[period].europeSessions += totalSessions;
        if (country === "US") acc[period].usSessions += totalSessions;
        return acc;
      }, {});

      const updatedResults = Object.keys(resultsObj).flatMap(period => [
        { period, country: "US", totalSessions: resultsObj[period].usSessions },
        { period, country: "EU", totalSessions: resultsObj[period].europeSessions },
        { period, country: "Total", totalSessions: resultsObj[period].totalSessions }
      ]);

      processedData.push({ id: metric.id, results: updatedResults });
    },

    [PAGE_SESSIONS_STAT](metric) {
      const totalsObj = metric.results.reduce((acc, curr) => {
        acc[curr.period] = (acc[curr.period] || 0) + curr.totalSessions;
        return acc;
      }, {});

      const updatedResults = [
        ...metric.results,
        ...Object.keys(totalsObj).map(period => ({
          period,
          pageFormat: "Total",
          totalSessions: totalsObj[period]
        }))
      ];

      processedData.push({ id: metric.id, results: updatedResults });
    },

    [REGULATION_CONSENT_STAT](metric) {
      [REGULATION_TO_ID_MAP.GDPR, REGULATION_TO_ID_MAP.CCPA].forEach(regulation => {
        const result = metric.results.find(item => item.regulation === regulation);
        if (result) {
          processedData.push({
            id: `${REGULATION_MAP[regulation].toLowerCase()}_consent_distribution`,
            results: [result]
          });
        }
      });
    }
  };

  metricsData.forEach(metric => {
    if (processMetric[metric.id]) {
      processMetric[metric.id](metric);
    } else {
      processedData.push(metric);
    }
  });

  return processedData;
}

export async function getTrafficConsentTableData(context: QueryFunctionContext): Promise<iAnalyticsDataList> {
  const [, queryPayload] = context.queryKey;
  const { currentDate, comparisonDate }: iTableDataPayload = queryPayload;
  const { pCode }: iAnalyticsPayload = currentDate;

  const [currentDateAnalytics, comparisonDateAnalytics] = await Promise.all([
    getAnalyticsDataAPI(pCode, { filters: currentDate.filters, queries: currentDate.queries }),
    getAnalyticsDataAPI(pCode, { filters: comparisonDate.filters, queries: comparisonDate.queries })
  ]);

  if (currentDateAnalytics.length === 0) {
    return currentDateAnalytics;
  }
  const processedData = currentDateAnalytics.map(metric => {
    const comparisonItem = comparisonDateAnalytics.find(({ id }) => id === metric.id);
    if (comparisonItem) {
      const updatedResults = metric.results.map(item => {
        const comparisonResult = comparisonItem.results.find(({ country }) => country === item.country);
        return {
          ...item,
          totalSessionsChange: comparisonResult
            ? calculatePercentageChange(comparisonResult.totalSessions, item.totalSessions)
            : 0,
          optInPercentChange: comparisonResult
            ? calculatePercentageChange(comparisonResult.optInPercent, item.optInPercent)
            : 0
        };
      });

      return { id: metric.id, results: updatedResults };
    }
    return metric;
  });

  return processedData;
}

export async function getReportsData(context: QueryFunctionContext) {
  const [, pCode, payload] = context.queryKey;
  const response = await requestAnalytics.post(`/graph/pcode/${pCode}`, payload);
  return response;
}

export async function saveAndRunJob(context: QueryFunctionContext) {
  const [, pCode, payload] = context.queryKey;
  const response = await requestAnalytics.post(`/pcode/${pCode}/job`, payload);
  return response;
}

export async function updateJob(context: QueryFunctionContext) {
  const [, pCode, payload, jobId] = context.queryKey;
  const response = await requestAnalytics.put(`/pcode/${pCode}/job/${jobId}`, payload);
  return response;
}

export async function scheduleJob(context: QueryFunctionContext) {
  const [, pCode, payload] = context.queryKey;
  const response = await requestAnalytics.post(`/pcode/${pCode}/job`, payload);
  return response;
}

export async function deleteReport(context: QueryFunctionContext) {
  const [, pCode, jobId] = context.queryKey;
  const response = await requestAnalytics.delete(`/pcode/${pCode}/job/${jobId}`);
  return response;
}

export async function activateDeactivateJob(context: QueryFunctionContext) {
  const [, pCode, jobId, active] = context.queryKey;
  const queryString = active !== undefined ? `?active=${active}` : '';
  const response = await requestAnalytics.patch(`/pcode/${pCode}/job/${jobId}${queryString}`);
  return response;
}

export async function getJobsData(context: QueryFunctionContext) {
  const [, pCode, reportType, reportName ] = context.queryKey;
  const queryString = [
    reportType ? `type=${reportType}` : '',
    reportName ? `name=${reportName}` : ''
  ].filter(Boolean).join('&');
  const response = await requestAnalytics.get(`/pcode/${pCode}/jobs${queryString ? `?${queryString}` : ''}`);
  return response;
}

export async function getJobData(context: QueryFunctionContext) {
  const [, pCode, jobId] = context.queryKey;
  const response = await requestAnalytics.get(`/pcode/${pCode}/job/${jobId}`);
  return response;
}

export async function getAllThemes(context: QueryFunctionContext) {
  const [, pcode] = context.queryKey;
  // https://test.choice.inmobi.com/customization/v2/theme?pCode=KEx-Uzp6HXjYT&sort=themeName,ASC
  const response = await requestThemes.get(`/theme?pCode=${pcode}&sort=themeName,ASC`);
  return response;
}