import React from 'react';
import { Checkbox } from 'antd/es';
import { checkbox_style } from '../constants';
import Styles from './analytics.module.scss';

const applicableAndCustomizations = [
  {
      "displayName": "Cop Applicable",
      "fieldName": "copApplicable",
      "fieldType": "DIRECT",
      "visible": true
  },
  {
      "displayName": "Advanced Applicable",
      "fieldName": "advancedApplicable",
      "fieldType": "DIRECT",
      "visible": true
  },
  {
      "displayName": "Gbc Applicable",
      "fieldName": "gbcApplicable",
      "fieldType": "DIRECT",
      "visible": true
  }
];

const DimensionMetric = ({ data, selectedValues, handleChange, type, selectedDimensions }) => {
  let sortedData = data;

  let groupedData = sortedData.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  let content = <></>;

  if (type === 'dimension') {
    let sortedData = data;

    // Remove the existing "Applicable" and "Customizations" objects from the array
    sortedData = data.filter(item => !item.fieldName.includes('Applicable'));

    // Add the combined "Applicable and Customizations" at the end
    sortedData.push(...applicableAndCustomizations);
    content = (
      <>
        {sortedData
          .filter(el => el.visible)
          .map(el => {
            return (
              <Checkbox
                key={el.name}
                style={{ ...checkbox_style }}
                onChange={handleChange}
                value={el.fieldName}
                checked={selectedValues.includes(el.fieldName)}
              >
                {el.displayName}
              </Checkbox>
            );
          })}
      </>
    );
  } else if (type === 'metric') {
    if (!selectedDimensions.includes('gbcApplicable')) {
      // remove gbc key from groupedData
      groupedData = Object.keys(groupedData).reduce((acc, key) => {
        if (key !== 'GBC') {
          acc[key] = groupedData[key];
        }
        return acc;
      }, {});
    }

    if (!selectedDimensions.includes('copApplicable')) {
      // remove gbc key from groupedData
      groupedData = Object.keys(groupedData).reduce((acc, key) => {
        if (key !== 'Users') {
          acc[key] = groupedData[key];
        }
        return acc;
      }, {});
    }
    content = (
      <>
        {Object.keys(groupedData).map(category => (
          <details key={category}>
            <summary>{category}</summary>
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '10px',
              }}
            >
              {groupedData[category].map(item =>
                item.visible ? (
                  <Checkbox
                    key={item.fieldName}
                    style={{
                      ...checkbox_style,
                    }}
                    onChange={handleChange}
                    value={item.fieldName}
                    checked={selectedValues.includes(item.fieldName)}
                  >
                    {item.displayName}
                  </Checkbox>
                ) : null,
              )}
            </ul>
          </details>
        ))}
      </>
    );
  }

  return <div className={Styles.metrics_popover}>{content}</div>;
};

export default DimensionMetric;
