const TEXT = {
  GDPR_LABEL: 'European regulation (GDPR)',
  USP_LABEL: 'US regulation',
  BRAZIL: 'Brazil',
  CANADA: 'Canada',
  KOREA: 'Korea',
  JAPAN: 'Japan',
  TAIWAN: 'Taiwan',
  INDONESIA: 'Indonesia',
};

export default TEXT;
