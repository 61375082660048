export const consentOrPayOptions = [
  {
    label: 'Yes',
    value: true,
    key: 'YES'
  },
  {
    label: 'No',
    value: false,
    key: 'NO'
  }
];

export const callbackOptions = [
  {
    label: 'Specify re-direction URLs for user actions',
    value: 'publisher'
  },
  {
    label: "Use InMobi's consent APIs to redirect user to relevant page",
    value: 'self'
  }
];

export const CALLBACK_REDIRECT_METHOD = {
  SAME_TAB: 'SAME_TAB',
  NEW_TAB: 'NEW_TAB',
}

export const callbackRedirectOptions = [
  {
    label: 'Yes',
    value: CALLBACK_REDIRECT_METHOD.SAME_TAB,
    key: 'YES'
  },
  {
    label: 'No',
    value: CALLBACK_REDIRECT_METHOD.NEW_TAB,
    key: 'NO'
  }
];