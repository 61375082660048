import React from 'react';
import { Button, Space, Table } from 'antd/es';
import { DownloadOutlined } from '@ant-design/icons';
import Modal from '../../../../components/ui/modal';
import Style from './oldVersionBanner.module.scss';
import alertIcon from '../../../../assets/images/alert.svg';

// @flow
interface IProps {
  isOpen: boolean;
  data: Object[];
  download: Function;
  onClose: Function;
}

const OldVersionBanner = ({ isOpen, data, download, onClose }: IProps) => {
  const columns = [
    {
      title: 'Property Name',
      dataIndex: 'property',
      key: 'property'
    }
  ];

  const footer = () => (
    <Space
      direction="horizontal"
      className={Style.footer}
    >
      <p>
        Have further queries?
        {' '}
        <a
          href="https://support.inmobi.com/mytickets"
          target="_blank"
          rel="noopener noreferrer"
        >
          Raise a ticket
        </a>
      </p>
      <Button
        type="primary"
        className={Style.okBtn}
        onClick={onClose}
      >
        Ok, I will make the required changes
      </Button>
    </Space>
  );

  return (
    <Modal
      title={(
        <Space className={Style.modalTitle}>
          <img src={alertIcon} alt="alert" />
          <span>
            Action Required
          </span>
        </Space>
      )}
      open={isOpen}
      closable={false}
      width={600}
      centered
      footer={footer()}
      className={Style.versionBanner}
    >
      <Space direction="vertical" size={16}>
        <span className={Style.deadline}>
          Deadline: 22nd Jan, 2024
        </span>
        <p>
          We have noticed that the below sites are using the older Quantcast Choice Website Tag.
          <b> Post Jan 22nd</b>
          , our CMP will not support requests coming from this version (v2 version).
        </p>

        <Space direction="vertical">
          <p>Please verify and update the below properties to current version (v3):</p>
          <Table columns={columns} dataSource={data} scroll={{ y: 140 }} size="small" pagination={false} />
          <Button className={Style.download} onClick={download}>
            <span>{'Download Full Property List'}</span>
            <span>{<DownloadOutlined />}</span>
          </Button>
        </Space>

        <Space direction="vertical" className={Style.details}>
          <p className={Style.title}>How to update?</p>
          <ol>
            <li>
              Select the
              <b> Universal Tag</b>
              {' '}
              button at the top right of the CMP dashboard.
            </li>
            <li>
              <b>Copy </b>
              {' '}
              the InMobi Universal Tag and
              <b> paste it into your website’s header.</b>
            </li>
            <li>
              If you are using GTM tags, please ensure that you are using the latest version. Check
              { ' ' }
              <a
                href="https://support.inmobi.com/choice/how-to-guide/integrate-the-tag-or-sdk/site-implementation-tag/google-tag-manager-gtm-implementation#assumptions"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </li>
          </ol>
        </Space>
      </Space>
    </Modal>
  );
};

export default OldVersionBanner;
