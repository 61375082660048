import moment from 'moment';

/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const COUNTRY_CODES = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Äland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antartica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia (Plurinational State of)', value: 'BO' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Côte d´Ivoire', value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czechia', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Holy See', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran (Islamic Republic of)', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Korea (Democratic People´s Republic of)', value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Lao People´s Democratic Republic', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia(Federated States of)', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Sint Maarten', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisa', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom of Great Britain and Northern Ireland', value: 'GB' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'United States of America', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Virgin Islands (British)', value: 'VG' },
  { label: 'Virgin Islands (U.S.)', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];

export const EEA_AND_UK = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];

export const COUNTRY2_3 = {
  AF: 'AFG', // Afghanistan
  AL: 'ALB', // Albania
  DZ: 'DZA', // Algeria
  AS: 'ASM', // American Samoa
  AD: 'AND', // Andorra
  AO: 'AGO', // Angola
  AI: 'AIA', // Anguilla
  AQ: 'ATA', // Antarctica
  AG: 'ATG', // Antigua and Barbuda
  AR: 'ARG', // Argentina
  AM: 'ARM', // Armenia
  AW: 'ABW', // Aruba
  AU: 'AUS', // Australia
  AT: 'AUT', // Austria
  AZ: 'AZE', // Azerbaijan
  BS: 'BHS', // Bahamas (the)
  BH: 'BHR', // Bahrain
  BD: 'BGD', // Bangladesh
  BB: 'BRB', // Barbados
  BY: 'BLR', // Belarus
  BE: 'BEL', // Belgium
  BZ: 'BLZ', // Belize
  BJ: 'BEN', // Benin
  BM: 'BMU', // Bermuda
  BT: 'BTN', // Bhutan
  BO: 'BOL', // Bolivia (Plurinational State of)
  BQ: 'BES', // Bonaire, Sint Eustatius and Saba
  BA: 'BIH', // Bosnia and Herzegovina
  BW: 'BWA', // Botswana
  BV: 'BVT', // Bouvet Island
  BR: 'BRA', // Brazil
  IO: 'IOT', // British Indian Ocean Territory (the)
  BN: 'BRN', // Brunei Darussalam
  BG: 'BGR', // Bulgaria
  BF: 'BFA', // Burkina Faso
  BI: 'BDI', // Burundi
  CV: 'CPV', // Cabo Verde
  KH: 'KHM', // Cambodia
  CM: 'CMR', // Cameroon
  CA: 'CAN', // Canada
  KY: 'CYM', // Cayman Islands (the)
  CF: 'CAF', // Central African Republic (the)
  TD: 'TCD', // Chad
  CL: 'CHL', // Chile
  CN: 'CHN', // China
  CX: 'CXR', // Christmas Island
  CC: 'CCK', // Cocos (Keeling) Islands (the)
  CO: 'COL', // Colombia
  KM: 'COM', // Comoros (the)
  CD: 'COD', // Congo (the Democratic Republic of the)
  CG: 'COG', // Congo (the)
  CK: 'COK', // Cook Islands (the)
  CR: 'CRI', // Costa Rica
  HR: 'HRV', // Croatia
  CU: 'CUB', // Cuba
  CW: 'CUW', // Curaçao
  CY: 'CYP', // Cyprus
  CZ: 'CZE', // Czechia
  CI: 'CIV', // Côte d'Ivoire
  DK: 'DNK', // Denmark
  DJ: 'DJI', // Djibouti
  DM: 'DMA', // Dominica
  DO: 'DOM', // Dominican Republic (the)
  EC: 'ECU', // Ecuador
  EG: 'EGY', // Egypt
  SV: 'SLV', // El Salvador
  GQ: 'GNQ', // Equatorial Guinea
  ER: 'ERI', // Eritrea
  EE: 'EST', // Estonia
  SZ: 'SWZ', // Eswatini
  ET: 'ETH', // Ethiopia
  FK: 'FLK', // Falkland Islands (the) [Malvinas]
  FO: 'FRO', // Faroe Islands (the)
  FJ: 'FJI', // Fiji
  FI: 'FIN', // Finland
  FR: 'FRA', // France
  GF: 'GUF', // French Guiana
  PF: 'PYF', // French Polynesia
  TF: 'ATF', // French Southern Territories (the)
  GA: 'GAB', // Gabon
  GM: 'GMB', // Gambia (the)
  GE: 'GEO', // Georgia
  DE: 'DEU', // Germany
  GH: 'GHA', // Ghana
  GI: 'GIB', // Gibraltar
  GR: 'GRC', // Greece
  GL: 'GRL', // Greenland
  GD: 'GRD', // Grenada
  GP: 'GLP', // Guadeloupe
  GU: 'GUM', // Guam
  GT: 'GTM', // Guatemala
  GG: 'GGY', // Guernsey
  GN: 'GIN', // Guinea
  GW: 'GNB', // Guinea-Bissau
  GY: 'GUY', // Guyana
  HT: 'HTI', // Haiti
  HM: 'HMD', // Heard Island and McDonald Islands
  VA: 'VAT', // Holy See (the)
  HN: 'HND', // Honduras
  HK: 'HKG', // Hong Kong
  HU: 'HUN', // Hungary
  IS: 'ISL', // Iceland
  IN: 'IND', // India
  ID: 'IDN', // Indonesia
  IR: 'IRN', // Iran (Islamic Republic of)
  IQ: 'IRQ', // Iraq
  IE: 'IRL', // Ireland
  IM: 'IMN', // Isle of Man
  IL: 'ISR', // Israel
  IT: 'ITA', // Italy
  JM: 'JAM', // Jamaica
  JP: 'JPN', // Japan
  JE: 'JEY', // Jersey
  JO: 'JOR', // Jordan
  KZ: 'KAZ', // Kazakhstan
  KE: 'KEN', // Kenya
  KI: 'KIR', // Kiribati
  KP: 'PRK', // Korea (the Democratic People's Republic of)
  KR: 'KOR', // Korea (the Republic of)
  KW: 'KWT', // Kuwait
  KG: 'KGZ', // Kyrgyzstan
  LA: 'LAO', // Lao People's Democratic Republic (the)
  LV: 'LVA', // Latvia
  LB: 'LBN', // Lebanon
  LS: 'LSO', // Lesotho
  LR: 'LBR', // Liberia
  LY: 'LBY', // Libya
  LI: 'LIE', // Liechtenstein
  LT: 'LTU', // Lithuania
  LU: 'LUX', // Luxembourg
  MO: 'MAC', // Macao
  MG: 'MDG', // Madagascar
  MW: 'MWI', // Malawi
  MY: 'MYS', // Malaysia
  MV: 'MDV', // Maldives
  ML: 'MLI', // Mali
  MT: 'MLT', // Malta
  MH: 'MHL', // Marshall Islands (the)
  MQ: 'MTQ', // Martinique
  MR: 'MRT', // Mauritania
  MU: 'MUS', // Mauritius
  YT: 'MYT', // Mayotte
  MX: 'MEX', // Mexico
  FM: 'FSM', // Micronesia (Federated States of)
  MD: 'MDA', // Moldova (the Republic of)
  MC: 'MCO', // Monaco
  MN: 'MNG', // Mongolia
  ME: 'MNE', // Montenegro
  MS: 'MSR', // Montserrat
  MA: 'MAR', // Morocco
  MZ: 'MOZ', // Mozambique
  MM: 'MMR', // Myanmar
  NA: 'NAM', // Namibia
  NR: 'NRU', // Nauru
  NP: 'NPL', // Nepal
  NL: 'NLD', // Netherlands (the)
  NC: 'NCL', // New Caledonia
  NZ: 'NZL', // New Zealand
  NI: 'NIC', // Nicaragua
  NE: 'NER', // Niger (the)
  NG: 'NGA', // Nigeria
  NU: 'NIU', // Niue
  NF: 'NFK', // Norfolk Island
  MP: 'MNP', // Northern Mariana Islands (the)
  NO: 'NOR', // Norway
  OM: 'OMN', // Oman
  PK: 'PAK', // Pakistan
  PW: 'PLW', // Palau
  PS: 'PSE', // Palestine, State of
  PA: 'PAN', // Panama
  PG: 'PNG', // Papua New Guinea
  PY: 'PRY', // Paraguay
  PE: 'PER', // Peru
  PH: 'PHL', // Philippines (the)
  PN: 'PCN', // Pitcairn
  PL: 'POL', // Poland
  PT: 'PRT', // Portugal
  PR: 'PRI', // Puerto Rico
  QA: 'QAT', // Qatar
  MK: 'MKD', // Republic of North Macedonia
  RO: 'ROU', // Romania
  RU: 'RUS', // Russian Federation (the)
  RW: 'RWA', // Rwanda
  RE: 'REU', // Réunion
  BL: 'BLM', // Saint Barthélemy
  SH: 'SHN', // Saint Helena, Ascension and Tristan da Cunha
  KN: 'KNA', // Saint Kitts and Nevis
  LC: 'LCA', // Saint Lucia
  MF: 'MAF', // Saint Martin (French part)
  PM: 'SPM', // Saint Pierre and Miquelon
  VC: 'VCT', // Saint Vincent and the Grenadines
  WS: 'WSM', // Samoa
  SM: 'SMR', // San Marino
  ST: 'STP', // Sao Tome and Principe
  SA: 'SAU', // Saudi Arabia
  SN: 'SEN', // Senegal
  RS: 'SRB', // Serbia
  SC: 'SYC', // Seychelles
  SL: 'SLE', // Sierra Leone
  SG: 'SGP', // Singapore
  SX: 'SXM', // Sint Maarten (Dutch part)
  SK: 'SVK', // Slovakia
  SI: 'SVN', // Slovenia
  SB: 'SLB', // Solomon Islands
  SO: 'SOM', // Somalia
  ZA: 'ZAF', // South Africa
  GS: 'SGS', // South Georgia and the South Sandwich Islands
  SS: 'SSD', // South Sudan
  ES: 'ESP', // Spain
  LK: 'LKA', // Sri Lanka
  SD: 'SDN', // Sudan (the)
  SR: 'SUR', // Suriname
  SJ: 'SJM', // Svalbard and Jan Mayen
  SE: 'SWE', // Sweden
  CH: 'CHE', // Switzerland
  SY: 'SYR', // Syrian Arab Republic
  TW: 'TWN', // Taiwan (Province of China)
  TJ: 'TJK', // Tajikistan
  TZ: 'TZA', // Tanzania, United Republic of
  TH: 'THA', // Thailand
  TL: 'TLS', // Timor-Leste
  TG: 'TGO', // Togo
  TK: 'TKL', // Tokelau
  TO: 'TON', // Tonga
  TT: 'TTO', // Trinidad and Tobago
  TN: 'TUN', // Tunisia
  TR: 'TUR', // Turkey
  TM: 'TKM', // Turkmenistan
  TC: 'TCA', // Turks and Caicos Islands (the)
  TV: 'TUV', // Tuvalu
  UG: 'UGA', // Uganda
  UA: 'UKR', // Ukraine
  AE: 'ARE', // United Arab Emirates (the)
  GB: 'GBR', // United Kingdom of Great Britain and Northern Ireland (the)
  UM: 'UMI', // United States Minor Outlying Islands (the)
  US: 'USA', // United States of America (the)
  UY: 'URY', // Uruguay
  UZ: 'UZB', // Uzbekistan
  VU: 'VUT', // Vanuatu
  VE: 'VEN', // Venezuela (Bolivarian Republic of)
  VN: 'VNM', // Viet Nam
  VG: 'VGB', // Virgin Islands (British)
  VI: 'VIR', // Virgin Islands (U.S.)
  WF: 'WLF', // Wallis and Futuna
  EH: 'ESH', // Western Sahara
  YE: 'YEM', // Yemen
  ZM: 'ZMB', // Zambia
  ZW: 'ZWE', // Zimbabwe
  AX: 'ALA', // Åland Islands
};

export const LEGAL_BASIS_LIST = [
  {
    name: 'Consent',
    description:
      'The list of vendors who have registered for consent only, expand into each vendor to review specific purposes',
  },
  {
    name: 'Legitimate Interest',
    description:
      'The list of vendors who have registered for legitimate interest only, expand into each vendor to review specific purposes',
  },
  {
    name: 'Flexible',
    description:
      'The list of vendors who have registered for flexible legal basis, expand into each vendor to review specific purposes',
  },
  {
    name: 'Multi',
    description:
      'The list of vendors who have registered for multi legal basis, expand into each vendor to review specific purposes',
  },
];

export const ENVIRONMENT_LIST = [
  {
    name: 'Web',
    description: 'Web',
  },
  {
    name: 'Native App (Mobile)',
    description: 'Native App (Mobile)',
  },
  {
    name: 'Native App (CTV)',
    description: 'Native App (CTV)',
  },
  {
    name: 'Other',
    description: 'Other',
  },
];

export const TYPE_OF_SERVICE = [
  {
    name: 'SSP',
    description: 'SSP',
  },
  {
    name: 'DSP',
    description: 'DSP',
  },
  {
    name: 'Verification Tool',
    description: 'Verification',
  },
  {
    name: 'Ad Serving',
    description: 'Ad Serving',
  },
  {
    name: 'Header Bidding Service',
    description: 'Header Bidding Service',
  },
  {
    name: 'DMP / Data Provider',
    description: 'DMP / Data Provider',
  },
  {
    name: 'Identity Resolution Service',
    description: 'Identity resolution services',
  },
  {
    name: 'Content Delivery Network',
    description: 'Content delivery network',
  },
  {
    name: 'Recommendation Service',
    description: 'Recommendation Service',
  },
  {
    name: 'Website Analytics',
    description: 'Website Analytics',
  },
  {
    name: 'Buyer',
    description: 'Buyer',
  },
  {
    name: 'Campaign Analytics',
    description: 'Campaign Analytics',
  },
  {
    name: 'Audience Analytics',
    description: 'Audience Analytics',
  },
  {
    name: 'Other',
    description: 'Other',
  },
];

export const INTERNATIONAL_TRANSFER = [{ name: 'YES' }, { name: 'NO' }];

export const USER_CONSENT_SCOPE = [
  { value: 'inEU', label: 'Only users in the EEA or UK' },
  { value: 'always', label: 'Everyone' },
  { value: 'never', label: 'Never' },
];

export const GBC_LOCATIONS = [
  { value: 'EEA', label: 'EEA & UK' },
  { value: 'WORLDWIDE', label: 'Worldwide' },
  { value: 'USA', label: 'USA' },
];

export const GBC_LOCATION_DEFAULT = 'EEA';

export const GBC_SITE_DEFAULT_PURPOSES = [1, 2, 3, 4, 5, 6, 7].map(id => ({
  id,
  enabled: false,
  defaultValue: 'DENIED',
}));

export const CONSENT_SCOPE = [
  { value: 'service', label: 'Your site only' },
  { value: 'service group', label: 'A group of your sites only' },
];

export const REGULATION_MODES = [
  {
    key: 'EU Regulation',
    value: 'GDPR',
  },
  {
    key: 'US Regulation',
    value: 'USP',
  },
  {
    key: 'EU & US regulation',
    value: 'GDPR, USP',
  },
];

export const REGULATION_MODES_V2 = [
  {
    key: 'European Regulation (GDPR)',
    value: 'GDPR',
  },
  {
    key: 'US Regulation (MSPA/GPP)',
    value: 'USP',
  },
  {
    key: 'Both',
    value: 'GDPR, USP',
  },
];

export const PURPOSES_INFO = [
  { id: 1, name: 'Store and/or access information on a device' },
  { id: 2, name: 'Select basic ads' },
  { id: 3, name: 'Create a personalised ads profile' },
  { id: 4, name: 'Select personalised ads' },
  { id: 5, name: 'Create a personalised content profile' },
  { id: 6, name: 'Select personalised content' },
  { id: 7, name: 'Measure ad performance' },
  { id: 8, name: 'Measure content performance' },
  { id: 9, name: 'Apply market research to generate audience insights' },
  { id: 10, name: 'Develop and improve products' },
];

export const STACKS_INFO = [
  {
    id: 42,
    name: 'Personalised ads and content, ad and content measurement, audience insights and product development',
    purposes: [2, 3, 4, 5, 6, 7, 8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 1,
    name: 'Precise geolocation data, and identification through device scanning',
    purposes: [],
    specialFeatures: [1, 2],
  },
  {
    id: 2,
    name: 'Basic ads, and ad measurement',
    purposes: [2, 7],
    specialFeatures: [],
  },
  {
    id: 3,
    name: 'Personalised ads',
    purposes: [2, 3, 4],
    specialFeatures: [],
  },
  {
    id: 4,
    name: 'Basic ads, and ad measurement',
    purposes: [2, 7, 9],
    specialFeatures: [],
  },
  {
    id: 5,
    name: 'Basic ads, personalised ads profile, and ad measurement',
    purposes: [2, 3, 7],
    specialFeatures: [],
  },
  {
    id: 6,
    name: 'Personalised ads display, and measurement',
    purposes: [2, 4, 7],
    specialFeatures: [],
  },
  {
    id: 7,
    name: 'Personalised ads display, ad measurement, and audience insights',
    purposes: [2, 4, 7, 9],
    specialFeatures: [],
  },
  {
    id: 8,
    name: 'Personalised ads, and ad measurement',
    purposes: [2, 3, 4, 7],
    specialFeatures: [],
  },
  {
    id: 9,
    name: 'Personalised ads, ad measurement, and audience insights',
    purposes: [2, 3, 4, 7, 9],
    specialFeatures: [],
  },
  {
    id: 10,
    name: 'Personalised ads profile and display',
    purposes: [3, 4],
    specialFeatures: [],
  },
  {
    id: 11,
    name: 'Personalised content',
    purposes: [5, 6],
    specialFeatures: [],
  },
  {
    id: 12,
    name: 'Personalised content display, and content measurement',
    purposes: [6, 8],
    specialFeatures: [],
  },
  {
    id: 13,
    name: 'Personalised content display, content measurement and audience insights',
    purposes: [6, 8, 9],
    specialFeatures: [],
  },
  {
    id: 14,
    name: 'Personalised content, and content measurement',
    purposes: [5, 6, 8],
    specialFeatures: [],
  },
  {
    id: 15,
    name: 'Personalised content, content measurement and audience insights',
    purposes: [5, 6, 8, 9],
    specialFeatures: [],
  },
  {
    id: 16,
    name: 'Personalised content, content measurement, audience insights, and product development',
    purposes: [5, 6, 8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 17,
    name: 'Ad and content measurement, and audience insights',
    purposes: [7, 8, 9],
    specialFeatures: [],
  },
  {
    id: 18,
    name: 'Ad and content measurement',
    purposes: [7, 8],
    specialFeatures: [],
  },
  {
    id: 19,
    name: 'Ad measurement, and audience insights',
    purposes: [7, 9],
    specialFeatures: [],
  },
  {
    id: 20,
    name: 'Ad and content measurement, audience insights, and product development',
    purposes: [7, 8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 21,
    name: 'Content measurement, audience insights, and product development.',
    purposes: [8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 22,
    name: 'Content measurement, and product development',
    purposes: [8, 10],
    specialFeatures: [],
  },
  {
    id: 23,
    name: 'Personalised ads and content display, ad and content measurement',
    purposes: [2, 4, 6, 7, 8],
    specialFeatures: [],
  },
  {
    id: 24,
    name: 'Personalised ads and content display, ad and content measurement, and audience insights',
    purposes: [2, 4, 6, 7, 8, 9],
    specialFeatures: [],
  },
  {
    id: 25,
    name: 'Personalised ads and content, ad and content measurement',
    purposes: [2, 3, 4, 5, 6, 7, 8],
    specialFeatures: [],
  },
  {
    id: 26,
    name: 'Personalised ads and content, ad and content measurement, and audience insights',
    purposes: [2, 3, 4, 5, 6, 7, 8, 9],
    specialFeatures: [],
  },
  {
    id: 27,
    name: 'Personalised ads, and content profile',
    purposes: [3, 5],
    specialFeatures: [],
  },
  {
    id: 28,
    name: 'Personalised ads and content display',
    purposes: [2, 4, 6],
    specialFeatures: [],
  },
  {
    id: 29,
    name: 'Basic ads, ad and content measurement, and audience insights',
    purposes: [2, 7, 8, 9],
    specialFeatures: [],
  },
  {
    id: 30,
    name: 'Personalised ads display, personalised content, ad and content measurement, and audience insights',
    purposes: [2, 4, 5, 6, 7, 8, 9],
    specialFeatures: [],
  },
  {
    id: 31,
    name: `Personalised ads display, personalised content, ad and content measurement, audience insights,
    and product development`,
    purposes: [2, 4, 5, 6, 7, 8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 32,
    name: 'Basic ads, personalised content, ad and content measurement, and audience insights',
    purposes: [2, 5, 6, 7, 8, 9],
    specialFeatures: [],
  },
  {
    id: 33,
    name: 'Basic ads, personalised content, ad and content measurement, audience insights, and product development',
    purposes: [2, 5, 6, 7, 8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 34,
    name: 'Basic ads, personalised content, content measurement, and audience insights',
    purposes: [2, 5, 6, 8, 9],
    specialFeatures: [],
  },
  {
    id: 35,
    name: 'Basic ads, personalised content, content measurement, audience insights, and product development',
    purposes: [2, 5, 6, 8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 36,
    name: 'Basic ads, personalised content, and ad measurement',
    purposes: [2, 5, 6, 7],
    specialFeatures: [],
  },
  {
    id: 37,
    name: 'Basic ads, personalised content, ad measurement, and product development',
    purposes: [2, 5, 6, 7, 10],
    specialFeatures: [],
  },
  {
    id: 38,
    name: 'Personalised ads, ad measurement, and product development',
    purposes: [2, 3, 4, 7, 10],
    specialFeatures: [],
  },
  {
    id: 39,
    name: 'Personalised ads, ad measurement, audience insights and product development',
    purposes: [2, 3, 4, 7, 9, 10],
    specialFeatures: [],
  },
  {
    id: 40,
    name: 'Personalised ads, ad and content measurement, audience insights and product development',
    purposes: [2, 3, 4, 7, 8, 9, 10],
    specialFeatures: [],
  },
  {
    id: 41,
    name: `Personalised ads, personalised content display, ad and content measurement,
    audience insights and product development`,
    purposes: [2, 3, 4, 6, 7, 8, 9, 10],
    specialFeatures: [],
  },
];

export const SPECIAL_FEATURES_INFO = [
  { id: 1, name: 'Use precise geolocation data' },
  { id: 2, name: 'Actively scan device characteristics for identification' },
];

export const SPECIAL_PURPOSES_INFO = [
  {
    id: 1,
    name: 'Ensure security, prevent fraud, and debug',
  },
  {
    id: 2,
    name: 'Technically deliver ads or content',
  },
];

export const FEATURES_INFO = [
  { id: 1, name: 'Match and combine offline data sources' },
  { id: 2, name: 'Link different devices' },
  { id: 3, name: 'Receive and use automatically-sent device characteristics for identification' },
];

export const PURPOSES_INFO_V2 = [
  { id: 1, name: 'Store and/or access information on a device' },
  { id: 2, name: 'Use limited data to select advertising' },
  { id: 3, name: 'Create profiles for personalised advertising' },
  { id: 4, name: 'Use profiles to select personalised advertising' },
  { id: 5, name: 'Create profiles to personalise content' },
  { id: 6, name: 'Use profiles to select personalised content' },
  { id: 7, name: 'Measure advertising performance' },
  { id: 8, name: 'Measure content performance' },
  { id: 9, name: 'Understand audiences through statistics or combinations of data from different sources' },
  { id: 10, name: 'Develop and improve services' },
  { id: 11, name: 'Use limited data to select content' }
];

export const SPECIAL_FEATURES_INFO_V2 = [
  { id: 1, name: 'Use precise geolocation data' },
  { id: 2, name: 'Actively scan device characteristics for identification' },
];

export const SPECIAL_PURPOSES_INFO_V2 = [
  {
    id: 1,
    name: 'Ensure security, prevent and detect fraud, and fix errors',
  },
  {
    id: 2,
    name: 'Deliver and present advertising and content',
  },
  {
    id: 3,
    name: 'Save and communicate privacy choices'
  }
];

export const FEATURES_INFO_V2 = [
  { id: 1, name: 'Match and combine data from other data sources' },
  { id: 2, name: 'Link different devices' },
  { id: 3, name: 'Identify devices based on information transmitted automatically' },
];

export const messages = { BULK_UPDATES_STATUS_ERROR: 'Failed to fetch the bulk update status.' };

export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!%$&'()*+,;=]+$/;

export const ALERT_IDENTIFIERS = {
  UNIVERSAL_TAG_ALERT: -999,
  AUDIT_WARNING_ALERT: -6,
  AUDIT_ERROR_ALERT: -7,
  AUDIT_SUCCESS_ALERT: -8,
  BULK_UPDATES_ERROR_ALERT: -9,
  BULK_UPDATES_SUCCESS_ALERT: -10,
  BULK_UPDATES_INQUEUE: -11,
  VENDOR_V3_LIST_BLOCK_LIST: -24,
  GBC_ANNOUNCEMENT: -9999,
  SURVEY_LINK: -99999,
  MIGRATION_ALERT: -100,
};

export const encodingOptions = [
  { label: 'GPP', value: 'GPP' },
  { label: 'TCF', value: 'TCF' },
  { label: 'Both', value: 'TCF_AND_GPP' },
];

export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const AMP_DOCUMENTATION_URL =
  'https://help.quantcast.com/hc/en-us/articles/13422445376283-Quantcast-Choice-AMP-Implementation-Guide-';

export const CURRENT_MAJOR_VERSION = '2';

export const FIELD_REQUIRED_TEXT = 'This field is required';
export const FIELD_URL_INVALID = 'URL field is not valid. (http:// or https:// protocol is needed)';
export const URL_IMAGE_INVALID = 'The image cannot be found!';
export const IMAGE_EXCEED_RATIO = 'The image ratio must be between 1:1 and 7:2';
export const GENERAL_FORM_ERROR = 'There are some errors you need to fix';
export const URL_FIELD_REQUIRED = 'The Link URL Field is required';
export const URL_TEXT_FIELD_REQUIRED = 'The text URL Field is required';
export const IMAGE_APP_INVALID_TYPE = 'Supported image types: PNG, JPG/JPEG, GIF and BMP.';
export const IMAGE_SITE_INVALID_TYPE = 'Supported image types: PNG, JPG/JPEG, GIF, BMP and SVG';
export const IMAGE_PUBLISHER_APP_EXT = /.(PNG|JPG|JPEG|GIF|BMP)$/i;
export const APP_ALREADY_EXIST = 'App already exists';
export const MSPA_OPT_OUT_PURPOSES_REQUIRED = 'Please select at least one purpose from the first four options';
export const MSPA_PURPOSES_REQUIRED = 'Please select at least one purpose';
export const NO_PROPERTIES_TEXTS = {
  title: 'Add your properties here',
  description:
    "To use the Inmobi consent management solution, first add the sites you'd like to protect. " +
    "After customizing your solution's UI, you'll be given a tag to install on your properties.",
};
export const XSS_ERROR_TEXT = 'Input includes forbidden characters. Please avoid using HTML or script tags.';
export const HEADER_REGULATION_TEXT = {
  GDPR: 'GDPR',
  CCPA: 'US Regulation',
  CCPA_V2: 'US Regulation',
};

export const GVL_VERSION_2 = 2;
export const GVL_VERSION_3 = 3;

export const AB_TESTCONSENT_TEXT = 'How visitors are interacting with your consent solution test.';

export const DEFAULT_THEME_IDS = ['-2', '-3'];
export const LATEST_CMP_VERSION = '54';
export const GBC_CMP_VERSION = '52';
export const GPP_CMP_VERSION = '54';

export const SENSITIVE_PURPOSE_ID = 5;
export const CHILD_PURPOSE_ID = 6;
export const OPT_OUT_PURPOSES = [1, 2, 3, 4];

export const WORLDWIDE = 'WORLDWIDE';
export const EEA = 'EEA';
export const USA = 'USA';
export const NEVER = 'NEVER';
export const GDPR = 'GDPR';
export const USP = 'USP';

export const REGULATION_MAP = {
  1: 'GDPR',
  2: 'CCPA',
  3: 'MSPA',
};

export const REGULATION_TO_ID_MAP = {
  GDPR: '1',
  CCPA: '2',
  MSPA: '3',
};
export const CONTINENTS = ['AF', 'AN', 'AS', 'EU', 'NA', 'OC', 'SA'];

export const CONTINENT_WITH_COUNTRIES = {
  AF: [
    { code: 'AO', name: 'Angola' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BI', name: 'Burundi' },
    { code: 'BJ', name: 'Benin' },
    { code: 'BW', name: 'Botswana' },
    { code: 'CD', name: 'Congo (Kinshasa)' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'CG', name: 'Congo (Brazzaville)' },
    { code: 'CI', name: "Côte d'Ivoire" },
    { code: 'CM', name: 'Cameroon' },
    { code: 'CV', name: 'Cape Verde' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DZ', name: 'Algeria' },
    { code: 'EG', name: 'Egypt' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GH', name: 'Ghana' },
    { code: 'GM', name: 'Gambia' },
    { code: 'GN', name: 'Guinea' },
    { code: 'GQ', name: 'Equatorial Guinea' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'KE', name: 'Kenya' },
    { code: 'KM', name: 'Comoros' },
    { code: 'LR', name: 'Liberia' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LY', name: 'Libya' },
    { code: 'MA', name: 'Morocco' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'ML', name: 'Mali' },
    { code: 'MR', name: 'Mauritania' },
    { code: 'MU', name: 'Mauritius' },
    { code: 'MW', name: 'Malawi' },
    { code: 'MZ', name: 'Mozambique' },
    { code: 'NA', name: 'Namibia' },
    { code: 'NE', name: 'Niger' },
    { code: 'NG', name: 'Nigeria' },
    { code: 'RE', name: 'Reunion' },
    { code: 'RW', name: 'Rwanda' },
    { code: 'SC', name: 'Seychelles' },
    { code: 'SD', name: 'Sudan' },
    { code: 'SH', name: 'Saint Helena' },
    { code: 'SL', name: 'Sierra Leone' },
    { code: 'SN', name: 'Senegal' },
    { code: 'SO', name: 'Somalia' },
    { code: 'SS', name: 'South Sudan' },
    { code: 'ST', name: 'Sao Tome and Principe' },
    { code: 'SZ', name: 'Eswatini' },
    { code: 'TD', name: 'Chad' },
    { code: 'TG', name: 'Togo' },
    { code: 'TN', name: 'Tunisia' },
    { code: 'TZ', name: 'Tanzania' },
    { code: 'UG', name: 'Uganda' },
    { code: 'YT', name: 'Mayotte' },
    { code: 'ZA', name: 'South Africa' },
    { code: 'ZM', name: 'Zambia' },
    { code: 'ZW', name: 'Zimbabwe' },
  ],
  AN: [
    { code: 'AQ', name: 'Antarctica' },
    { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { code: 'TF', name: 'French Southern Territories' },
  ],
  AS: [
    { code: 'AE', name: 'United Arab Emirates' },
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AM', name: 'Armenia' },
    { code: 'AZ', name: 'Azerbaijan' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BH', name: 'Bahrain' },
    { code: 'BN', name: 'Brunei' },
    { code: 'BT', name: 'Bhutan' },
    { code: 'CC', name: 'Cocos (Keeling) Islands' },
    { code: 'CN', name: 'China' },
    { code: 'GE', name: 'Georgia' },
    { code: 'HK', name: 'Hong Kong' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'IL', name: 'Israel' },
    { code: 'IN', name: 'India' },
    { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'IQ', name: 'Iraq' },
    { code: 'IR', name: 'Iran' },
    { code: 'JO', name: 'Jordan' },
    { code: 'JP', name: 'Japan' },
    { code: 'KG', name: 'Kyrgyzstan' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'KP', name: 'North Korea' },
    { code: 'KR', name: 'South Korea' },
    { code: 'KW', name: 'Kuwait' },
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'LA', name: 'Laos' },
    { code: 'LB', name: 'Lebanon' },
    { code: 'LK', name: 'Sri Lanka' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'MN', name: 'Mongolia' },
    { code: 'MO', name: 'Macau' },
    { code: 'MV', name: 'Maldives' },
    { code: 'MY', name: 'Malaysia' },
    { code: 'NP', name: 'Nepal' },
    { code: 'OM', name: 'Oman' },
    { code: 'PH', name: 'Philippines' },
    { code: 'PK', name: 'Pakistan' },
    { code: 'PS', name: 'Palestine' },
    { code: 'QA', name: 'Qatar' },
    { code: 'RU', name: 'Russia' },
    { code: 'SA', name: 'Saudi Arabia' },
    { code: 'SG', name: 'Singapore' },
    { code: 'SY', name: 'Syria' },
    { code: 'TH', name: 'Thailand' },
    { code: 'TJ', name: 'Tajikistan' },
    { code: 'TM', name: 'Turkmenistan' },
    { code: 'TW', name: 'Taiwan' },
    { code: 'UZ', name: 'Uzbekistan' },
    { code: 'VN', name: 'Vietnam' },
    { code: 'YE', name: 'Yemen' },
  ],
  EU: [
    { code: 'AD', name: 'Andorra' },
    { code: 'AL', name: 'Albania' },
    { code: 'AT', name: 'Austria' },
    { code: 'AX', name: 'Åland Islands' },
    { code: 'BA', name: 'Bosnia and Herzegovina' },
    { code: 'BE', name: 'Belgium' },
    { code: 'BG', name: 'Bulgaria' },
    { code: 'BY', name: 'Belarus' },
    { code: 'CH', name: 'Switzerland' },
    { code: 'CY', name: 'Cyprus' },
    { code: 'CZ', name: 'Czech Republic' },
    { code: 'DE', name: 'Germany' },
    { code: 'DK', name: 'Denmark' },
    { code: 'EE', name: 'Estonia' },
    { code: 'ES', name: 'Spain' },
    { code: 'FI', name: 'Finland' },
    { code: 'FO', name: 'Faroe Islands' },
    { code: 'FR', name: 'France' },
    { code: 'GB', name: 'United Kingdom' },
    { code: 'GG', name: 'Guernsey' },
    { code: 'GI', name: 'Gibraltar' },
    { code: 'GR', name: 'Greece' },
    { code: 'HR', name: 'Croatia' },
    { code: 'HU', name: 'Hungary' },
    { code: 'IE', name: 'Ireland' },
    { code: 'IM', name: 'Isle of Man' },
    { code: 'IS', name: 'Iceland' },
    { code: 'IT', name: 'Italy' },
    { code: 'JE', name: 'Jersey' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LT', name: 'Lithuania' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'LV', name: 'Latvia' },
    { code: 'MC', name: 'Monaco' },
    { code: 'MD', name: 'Moldova' },
    { code: 'ME', name: 'Montenegro' },
    { code: 'MK', name: 'North Macedonia' },
    { code: 'MT', name: 'Malta' },
    { code: 'NL', name: 'Netherlands' },
    { code: 'NO', name: 'Norway' },
    { code: 'PL', name: 'Poland' },
    { code: 'PT', name: 'Portugal' },
    { code: 'RO', name: 'Romania' },
    { code: 'RS', name: 'Serbia' },
    { code: 'SE', name: 'Sweden' },
    { code: 'SI', name: 'Slovenia' },
    { code: 'SJ', name: 'Svalbard and Jan Mayen' },
    { code: 'SK', name: 'Slovakia' },
    { code: 'SM', name: 'San Marino' },
    { code: 'UA', name: 'Ukraine' },
    { code: 'VA', name: 'Vatican City' },
    { code: 'XK', name: 'Kosovo' },
  ],
  NA: [
    { code: 'AG', name: 'Antigua and Barbuda' },
    { code: 'AI', name: 'Anguilla' },
    { code: 'AW', name: 'Aruba' },
    { code: 'BB', name: 'Barbados' },
    { code: 'BL', name: 'Saint Barthélemy' },
    { code: 'BM', name: 'Bermuda' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BZ', name: 'Belize' },
    { code: 'CA', name: 'Canada' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CW', name: 'Curaçao' },
    { code: 'DM', name: 'Dominica' },
    { code: 'DO', name: 'Dominican Republic' },
    { code: 'GL', name: 'Greenland' },
    { code: 'GD', name: 'Grenada' },
    { code: 'GP', name: 'Guadeloupe' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'HN', name: 'Honduras' },
    { code: 'HT', name: 'Haiti' },
    { code: 'JM', name: 'Jamaica' },
    { code: 'KN', name: 'Saint Kitts and Nevis' },
    { code: 'KY', name: 'Cayman Islands' },
    { code: 'LC', name: 'Saint Lucia' },
    { code: 'MF', name: 'Saint Martin' },
    { code: 'MQ', name: 'Martinique' },
    { code: 'MS', name: 'Montserrat' },
    { code: 'MX', name: 'Mexico' },
    { code: 'NI', name: 'Nicaragua' },
    { code: 'PA', name: 'Panama' },
    { code: 'PM', name: 'Saint Pierre and Miquelon' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'SX', name: 'Sint Maarten' },
    { code: 'TC', name: 'Turks and Caicos Islands' },
    { code: 'TT', name: 'Trinidad and Tobago' },
    { code: 'US', name: 'United States' },
    { code: 'VC', name: 'Saint Vincent and the Grenadines' },
    { code: 'VG', name: 'British Virgin Islands' },
    { code: 'VI', name: 'U.S. Virgin Islands' },
  ],
  OC: [
    { code: 'AS', name: 'American Samoa' },
    { code: 'AU', name: 'Australia' },
    { code: 'CK', name: 'Cook Islands' },
    { code: 'FJ', name: 'Fiji' },
    { code: 'FM', name: 'Micronesia' },
    { code: 'GU', name: 'Guam' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'MH', name: 'Marshall Islands' },
    { code: 'MP', name: 'Northern Mariana Islands' },
    { code: 'NC', name: 'New Caledonia' },
    { code: 'NF', name: 'Norfolk Island' },
    { code: 'NR', name: 'Nauru' },
    { code: 'NU', name: 'Niue' },
    { code: 'NZ', name: 'New Zealand' },
    { code: 'PF', name: 'French Polynesia' },
    { code: 'PG', name: 'Papua New Guinea' },
    { code: 'PN', name: 'Pitcairn Islands' },
    { code: 'PW', name: 'Palau' },
    { code: 'SB', name: 'Solomon Islands' },
    { code: 'TK', name: 'Tokelau' },
    { code: 'TO', name: 'Tonga' },
    { code: 'TV', name: 'Tuvalu' },
    { code: 'UM', name: 'United States Minor Outlying Islands' },
    { code: 'VU', name: 'Vanuatu' },
    { code: 'WF', name: 'Wallis and Futuna' },
    { code: 'WS', name: 'Samoa' },
  ],
  SA: [
    { code: 'AR', name: 'Argentina' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'BR', name: 'Brazil' },
    { code: 'CL', name: 'Chile' },
    { code: 'CO', name: 'Colombia' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'FK', name: 'Falkland Islands' },
    { code: 'GF', name: 'French Guiana' },
    { code: 'GY', name: 'Guyana' },
    { code: 'PE', name: 'Peru' },
    { code: 'PY', name: 'Paraguay' },
    { code: 'SR', name: 'Suriname' },
    { code: 'UY', name: 'Uruguay' },
    { code: 'VE', name: 'Venezuela' },
  ],
};

export const TIME = {
  MONTH_TILL_DATE: 'Month till Date',
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  LAST_3_MONTHS: 'Last 3 months',
  CUSTOM_DATE: 'Custom Date',
};

export const TIME_FILTERS = [
  TIME.MONTH_TILL_DATE,
  TIME.LAST_7_DAYS,
  TIME.LAST_30_DAYS,
  TIME.LAST_3_MONTHS,
  TIME.CUSTOM_DATE,
];

export const TIME_PERIOD_TYPE = [
  {
    label: 'This Week',
    value: 'currentWeek',
  },
  {
    label: TIME.MONTH_TILL_DATE,
    value: 'currentMonth',
  },
  {
    label: TIME.LAST_7_DAYS,
    value: 'last7Days',
  },
  {
    label: TIME.LAST_30_DAYS,
    value: 'last30Days',
  },
  {
    label: TIME.LAST_3_MONTHS,
    value: 'last3Months',
  },
  {
    label: TIME.CUSTOM_DATE,
    value: 'fixed',
  },
];

// Mapping for regulation values to names
export const regulationMap = {
  '0': 'Google Consent Mode',
  '1': 'GDPR',
  '2': 'CCPA',
  '3': 'MSPA',
  '4': 'No Regulation'
};

export const REGULATION_FILTERS = [
  {
    label: 'GDPR',
    name: 1,
  },
  {
    label: 'CCPA',
    name: 2,
  },
  {
    label: 'MSPA',
    name: 3,
  },
];

export const DEVICE_TYPE_FILTERS = [
  {
    label: 'PC',
    name: 'pc',
  },
  {
    label: 'Tablet',
    name: 'tablet',
  },
  {
    label: 'Mobile',
    name: 'mobile',
  },
  {
    label: 'Others',
    name: 'others',
  },
];

export const OS_FILTERS = [
  {
    label: 'Android',
    name: 'Android',
  },
  {
    label: 'iOS',
    name: 'iOS',
  },
  {
    label: 'Windows',
    name: 'Windows',
  },
  {
    label: 'Mac OS',
    name: 'Mac OS',
  },
  {
    label: 'Linux',
    name: 'Linux',
  },
  {
    label: 'IPad OS',
    name: 'iPadOS',
  },
  {
    label: 'Others',
    name: 'others',
  },
];

export const PAGE_FILTERS = [
  {
    label: 'HTTP',
    name: 'HTTP',
  },
  {
    label: 'AMP',
    name: 'AMP',
  },
  {
    label: 'Others',
    name: 'Others',
  },
];
export const GRANULARITY_FILTERS = [
  {
    label: 'Day',
    name: 'day',
  },
  {
    label: 'Week',
    name: 'week',
  },
  {
    label: 'Month',
    name: 'month',
  },
];

export const BROWSER_FILTERS = [
  {
    label: 'Chrome',
    name: 'Chrome',
  },
  {
    label: 'Safari',
    name: 'Safari',
  },
  {
    label: 'Edge',
    name: 'Edge',
  },
  {
    label: 'Samsung Internet',
    name: 'Samsung Internet',
  },
  {
    label: 'Firefox',
    name: 'Firefox',
  },
  {
    label: 'Others',
    name: 'Others',
  },
];

export const REPORT_TYPES = {
  ON_DEMAND: 'on_demand',
  SCHEDULED: 'scheduled',
};

export const FILTER_TYPE = {
  REGULATION: 'regulation',
  DEVICE_TYPE: 'deviceType',
  OS: 'os',
  PAGE_FORMAT: 'pageFormat',
  BROWSER: 'browser',
  COUNTRY: 'country',
  TIME: 'time',
  GRANULARITY: 'granularity',
  PROPERTY: 'propertyId',
  COP_APPLICABLE: 'copApplicable',
  ADVANCED_APPLICABLE: 'advancedApplicable',
  GBC_APPLICABLE: 'gbcApplicable',
  THEME_UUID: 'themeUuid',
  THEME_LANGUAGE: 'themeLanguage',
};

export const DIMENSIONS = [
  { label: 'Regulation', name: 'regulation' },
  { label: 'Country/Region', name: 'country' },
  { label: 'Device Type', name: 'deviceType' },
  { label: 'Operating System', name: 'os' },
  { label: 'Page Format', name: 'pageFormat' },
  { label: 'Browser', name: 'browser' },
  { label: 'Property', name: FILTER_TYPE.PROPERTY },
  { label: 'Time', name: 'time' },
  { label: 'Advanced Customizations', name: 'advancedApplicable' },
  { label: 'COP Applicable', name: 'copApplicable' },
  { label: 'Gbc Applicable', name: 'gbcApplicable' },
  { label: 'Theme UUID', name: 'themeUuid' },
  { label: 'Theme Language', name: 'themeLanguage' },
];

export const REPORT_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  RUNNING: 'RUNNING',
};

export const BRAZIL = 'BRAZIL';
export const CANADA = 'CANADA';
export const OLD_VERSION = 'OLDV';
export const NO_DATA = 'No Data';
