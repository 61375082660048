import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import { iMetaData } from "../../interfaces/analytics_v1";
import {
  DIMENSIONS_METRICS_MAP,
  MONTH_NAMES,
  REGULATION_OPTIONS,
  COLOR_MAP,
  REGULATION_OPTIN_STAT,
  REGULATION_TRAFFIC_STAT,
  REGION_TRAFFIC_STAT,
  PAGE_SESSIONS_STAT,
  GDPR_CONSENT_STAT,
  CCPA_CONSENT_STAT,
  COUNTRY_TRAFFIC_OPTIN_STAT,
  FIELD_NAMES,
  STATUS,
  SUMMARY_ICON_MAP,
  VALUE_TYPE,
  DATE_FORMAT,
  DEVICE_TYPE_OPTIONS,
  REGULATION_SESSIONS_STAT,
  REGION_SESSIONS_STAT
} from "./constants";
import { COUNTRY_CODES, REGULATION_MAP, REGULATION_TO_ID_MAP } from "../../utils/constants";
import { Tag, Space, Divider } from "antd";
import Style from "./premiumReports.module.scss";
import { FILTER_DISPLAY_NAME } from "./constants";
import React from "react";
import * as Highcharts from "highcharts";

dayjs.extend(utc);
dayjs.extend(isBetween);

// @flow
interface ChartContext {
  x: string;
  y: number;
  series: {
    userOptions: {
      type: string
    }
  };
}
export function formatNumber(n: number) {
  if (n == 0) return 0;
  if (!n) return;
  if (n < 1e3) return +n.toFixed(1);
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
  return n;
}

export function getMetricFieldNames(metaData: iMetaData) {
  return metaData.metrics.filter(metric => metric.category === "Sessions").map(metric => metric.fieldName);
}

export const getLabel = (item, key) => {
  let options = [];
  if(key === "regulation") options = REGULATION_OPTIONS;
  if(key === "deviceType") options = DEVICE_TYPE_OPTIONS;
  if(key === "country") options = COUNTRY_CODES;

  if(options.length === 0) return item;
  const { label } = options.find(option => option.value === item);
  return label;
};

export const getLast2MonthOptions = () => {
  const currentMonth = dayjs.utc().month();
  const lastMonth = currentMonth - 1;
  if (currentMonth > 1) {
    return [
      { label: MONTH_NAMES[lastMonth], value: lastMonth },
      { label: MONTH_NAMES[lastMonth - 1], value: lastMonth - 1 }
    ];
  }
  if (currentMonth === 1) {
    return [{ label: MONTH_NAMES[0], value: 0 }];
  }
  return [];
};

export function getLastNDaysRange(
  n: number
): {
  start: Dayjs,
  end: Dayjs
} {
  const end = dayjs
    .utc()
    .subtract(1, "day")
    .endOf("day");
  const start = dayjs
    .utc()
    .subtract(n, "days")
    .startOf("day");
  return {
    start,
    end
  };
}

export const getCurrentDefaultDateRange = () => {
  const isBefore7 = dayjs().utc().hour() < 7;
  let end = 1;
  if (isBefore7) {
    end = 2;
  }
  const start = end + 6;
  return [dayjs().utc().subtract(start, 'day').startOf('day'),
    dayjs().utc().subtract(end, 'day').endOf('day')];
};

export const getCompairionsDefaultDateRange = () => {
  const [start, end] = getCurrentDefaultDateRange();
  return [start.utc().subtract(7, 'day').startOf('day'),
    start.utc().subtract(1, 'day').endOf('day')];
};

export function getNumberofDaysInRange(start: String, end: string) {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  return endDate.diff(startDate, "days") + 1;
}

export const shouldDateBeDisabled = (date: dayjs.Dayjs, days: number) => {
  const from = dayjs().utc().subtract(days, "days");
  const isAfter7 = dayjs().utc().hour() >= 7;
  const to = isAfter7 ? dayjs().utc().subtract(1, "day"): dayjs().utc().subtract(2, "day");
  return !dayjs(date).utc().isBetween(from, to);
};

export const customDateFormat = value => {
  const dateFormat = dayjs(value)
    .utc()
    .format("MM-DD");
  const [month, date] = dateFormat.split("-");
  return `${Number(date)} ${MONTH_NAMES[Number(month) - 1]}`;
};

export const getDateRanges = () => ({
  Yesterday: [getLastNDaysRange(1).start, getLastNDaysRange(1).end],
  "Last 7 Days": [getLastNDaysRange(7).start, getLastNDaysRange(7).end],
  "Last 30 Days": [getLastNDaysRange(30).start, getLastNDaysRange(30).end],
  "Month Till Date": [dayjs.utc().startOf("month"), getLastNDaysRange(1).end]
});

const isLineOrBarGraph = id =>
  [REGULATION_OPTIN_STAT, REGULATION_SESSIONS_STAT, REGION_SESSIONS_STAT, PAGE_SESSIONS_STAT].includes(id);

export const getLineOrBarGraphData = (dimension, metric, metricData) => {
  const data = [];
  let dimensionList = [];
  let nameMap = {};
  if (dimension === "regulation") {
    dimensionList = Object.values(REGULATION_TO_ID_MAP);
    nameMap = REGULATION_MAP;
  } else if (dimension === "country") {
    dimensionList = ["EU", "US", "Total"];
    nameMap = {
      EU: "Europe",
      US: "US",
      Total: "Total"
    };
  } else if (dimension === "pageFormat") {
    dimensionList = ["Total", "AMP", "HTTP"];
    nameMap = {
      Total: "All Pages",
      AMP: "AMP",
      HTTP: "HTTP"
    };
  }
  dimensionList.forEach((item, index) => {
    data.push({
      name: nameMap[item],
      color: COLOR_MAP[`COLOR_${index + 1}`],
      data: metricData.results
        .filter(result => result[dimension] === item)
        .map(result => {
          const dateParts = result.period.split("-");
          const utcDate = Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]);
          return {
            x: utcDate,
            y: Number(result[metric].toFixed(1))
          };
        })
        .sort((a, b) => a.x - b.x)
    });
  });
  return data;
};

export const getTableData = (dimension, metricsArr, metricData) =>
  metricData.results.flatMap(item => {
    let dimensionLabel = "";
    if (dimension === "country") {
      const countryObj = COUNTRY_CODES.find(option => option.value === item[dimension]);
      if (countryObj) {
        dimensionLabel = countryObj.label;
      }
    }
    if(!item[dimension]) {
      return [];
    }
    const obj = {
      key: item[dimension],
      [dimension]: dimensionLabel
    };
    metricsArr.forEach(metric => {
      obj[metric] = item[metric];
    });
    return obj;
  });

export const getPieGraphData = (metricArr, metricData) => {
  const data = [];
  const metricResult = metricData.results[0];
  const labelsMap = {
    optInPercent: "Accept all",
    optOutPercent: "Deny all",
    partialOptInPercent: "Partial opt-in",
    bouncePercent: "Bounce"
  };
  data.push({
    name: "percentage",
    data: metricArr.map((metric, index) => ({
      name: labelsMap[metric],
      y: Number(metricResult[metric].toFixed(1)),
      color: COLOR_MAP[`COLOR_${index + 1}`]
    }))
  });
  return data;
};

const getFilterPayload = (dateRange, advanceFilters, propertyId) => {
  const [start, end] = dateRange;

  const filterArr = [
    {
      key: "startDate",
      operator: "gteq",
      values: [start]
    },
    {
      key: "endDate",
      operator: "lteq",
      values: [end]
    }
  ];

  const {REGULATION, PAGE_FORMAT, DEVICE_TYPE, OS, COUNTRY, PROPERTY_ID} = FIELD_NAMES;

  [REGULATION, PAGE_FORMAT, DEVICE_TYPE, OS, COUNTRY].forEach(key => {
    if (advanceFilters[key].length > 0) {
      filterArr.push({
        key,
        operator: "in",
        values: advanceFilters[key]
      });
    }
  });

  if (propertyId.length > 0) {
    filterArr.push({
      key: PROPERTY_ID,
      operator: "in",
      values: propertyId
    });
  }
  return filterArr;
};

export const getAnalyticsPayload = (dateRange, advanceFilters, propertyId, pCode, granularity, queriesArr) => {
  const payload = {
    pCode,
    filters: getFilterPayload(dateRange, advanceFilters, propertyId),
    queries: queriesArr.map(id => ({
      id,
      granularity: isLineOrBarGraph(id) ? granularity : "none",
      dimensions: DIMENSIONS_METRICS_MAP[id].dimensions,
      metrics: DIMENSIONS_METRICS_MAP[id].metrics
    }))
  };
  return payload;
};

export const getAnalyticsDataForDisplay = metricsData => {
  const res = {};

  metricsData.forEach(metric => {
    const { dimensions, metrics } = DIMENSIONS_METRICS_MAP[metric.id];

    if (metric.results.length === 0) {
      res[metric.id] = [];
    } else {
      switch (metric.id) {
        case REGULATION_OPTIN_STAT:
        case REGULATION_SESSIONS_STAT:
        case REGION_SESSIONS_STAT:
        case PAGE_SESSIONS_STAT:
          res[metric.id] = getLineOrBarGraphData(dimensions[0], metrics[0], metric);
          break;
        case GDPR_CONSENT_STAT:
        case CCPA_CONSENT_STAT:
          res[metric.id] = getPieGraphData(metrics, metric);
          break;
        default:
          break;
      }
    }
  });
  return res;
};

export const showGraphsInFullScreen = numberOfDays => {
  if ((numberOfDays >= 6 && numberOfDays <= 15) || (numberOfDays >= 36 && numberOfDays <= 90)) {
    return true;
  }
  return false;
};

const getComparisonStatus = (comparisonPercentage: number, fieldName: string): IComparisonStatus => {
  if (comparisonPercentage === 0) return STATUS.NEUTRAL;
  if (fieldName === FIELD_NAMES.BOUNCE || fieldName === FIELD_NAMES.OPT_OUT) {
    return comparisonPercentage > 0 ? STATUS.NEGATIVE : STATUS.POSITIVE;
  }
  return comparisonPercentage > 0 ? STATUS.POSITIVE : STATUS.NEGATIVE;
};

const getMetricValueType = (fieldName: string) => {
  if (fieldName === FIELD_NAMES.TRAFFIC || fieldName === FIELD_NAMES.SESSIONS) return VALUE_TYPE.NUMBER;
  return VALUE_TYPE.PERCENTAGE;
};

export const getSummaryMetrics = (metaData, summaryData) => {
  const metrics = [];
  if (metaData && summaryData) {
    const metricNames = metaData.metrics.reduce((acc, curr) => {
      acc[curr.fieldName] = curr.displayName;
      return acc;
    }, {});

    summaryData.forEach(data => {
      const metric = {
        metricName: metricNames[data.fieldName],
        metricValue: data.value,
        comparisonPercentage: data.comparisonPercentage,
        comparisonStatus: getComparisonStatus(data.comparisonPercentage, data.fieldName),
        valueType: getMetricValueType(data.fieldName),
        iconImage: SUMMARY_ICON_MAP[data.fieldName]
      };
      metrics.push(metric);
    });
  }
  return metrics.filter(el => !el.metricName.includes('Visit'));
};

export const getFilterTags = (appliedFilters, deleteFilterItem) => {
  return Object.keys(appliedFilters).flatMap(key => {
    if (appliedFilters[key].length === 0) return null;
    return (
      <Space style={{ flexWrap: "wrap" }}>
        <p className={Style.filterLabel}>{FILTER_DISPLAY_NAME[key]}</p>
        {appliedFilters[key].map(item => (
          <Tag id={Style.tagItem} key={item} color="#D0DFFC" closable onClose={() => deleteFilterItem(key, item)}>
            {getLabel(item, key)}
          </Tag>
        ))}
        <Divider type="vertical" />
      </Space>
    );
  });
};

const labelFormatter = function(date, granularity, weekLabel = "") {
  if (granularity === "day") return Highcharts.dateFormat("%b %d", date);
  return weekLabel;
};

const getDateForToolTip = function(date, granularity, weekLabel = "") {
  if (granularity === "day") return Highcharts.dateFormat("%b %d", date);
  return (
    weekLabel +
    " ( " +
    Highcharts.dateFormat("%b %d", date) +
    " - " +
    Highcharts.dateFormat("%b %d", date + 1000 * 60 * 60 * 24 * 6) +
    " )"
  );
};

export const getToolTipContent = (
  chartContext: ChartContext,
  graphType: "bar" | "line" | "pie",
  granularity: string,
  format: string,
  label: string
) => {
  const { series, y, x: date, point } = chartContext;
  if (graphType === "pie") {
    return "<div>" + point.name + ": " + y + "%" + "</div>";
  }

  const yAxisLabel = series.yAxis.axisTitle.textStr === "%" ? "%" : "K";
  const yValue = series.yAxis.axisTitle.textStr === "Opt-in" ? `${y.toFixed(1)}%` : formatNumber(y);
  return (
    "<div>" +
    getDateForToolTip(date, granularity, label) +
    "</div> <br/>" +
    "<div>" +
    "<b>" +
    series.name +
    ": " +
    yValue +
    "</div>"
  );
};

export const getWeekLabels = (graphData, granularity) => {
  if (!graphData || !graphData.length || granularity === 'day') return {};

  const uniqueDates = new Set(
    graphData.flatMap(data => {
      const seriesData = data.data;
      if(seriesData.length === 0) return [];
      return seriesData.map(seri => seri.x);
    })
  );

  const labels = {};
  const datesArr =  Array.from(uniqueDates).sort((a, b) => a - b);
  datesArr.forEach((date, index) => {
    labels[date] = `Week ${index + 1}`;
  });

  return labels;
};

export const getGraphOptions = (
  graphType: "bar" | "line" | "pie",
  graphData: any,
  granularity: string,
  labels?: Record<string, string>
) => {
  const weekLabels = getWeekLabels(graphData, granularity);
  // Common chart options
  const commonOptions = {
    title: { text: "" },
    chart: {
      backgroundColor: "rgba(241, 245, 254, 1)"
    },
    credits: { enabled: false },
    legend: {
      align: "left",
      verticalAlign: "bottom",
      layout: "horizontal",
      itemStyle: { fontSize: "12px", fontWeight: 400 },
      symbolHeight: 16,
      symbolWidth: 18,
      symbolRadius: 0,
      title: {
        text: 'Click to Select/ Un-select categories here:',
        style: { fontSize: "11px", fontWeight: 400 },
      },
      itemMarginTop: 8
    },
    tooltip: {
      formatter: function() {
        const date = this.x;
        return getToolTipContent(this, graphType, granularity, DATE_FORMAT, weekLabels[date]);
      }
    },
    series: graphData
  };

  // Chart options specific to bar and line charts
  const axisOptions = {
    yAxis: {
      title: { text: labels ? labels.y : "", style: { fontSize: "12px" } },
      labels: { style: { fontSize: "12px" } },
      lineWidth: 1,
      visible: true,
      max: labels && labels.y === "%" ? 100 : null
    },
    xAxis: {
      title: { text: labels ? labels.x : "", style: { fontSize: "12px" } },
      labels: {
        style: { fontSize: "12px" },
        formatter: function() {
          const date = this.value;
          return labelFormatter(date, granularity, weekLabels[date]);
        }
      },
      type: "datetime",
      tickPositioner: function () {
      	var info = this.tickPositions.info,
        ticks = this.series[0].processedXData.slice(0);
        ticks.info = info;
        return ticks;
      },
      tickLength: 0,
      tickWidth: 0
    }
  };

  switch (graphType) {
    case "bar":
      return {
        ...commonOptions,
        chart: { ...commonOptions.chart, type: "column" },
        ...axisOptions,
        plotOptions: {
          series: {
            pointPadding: 0,
            minPointLength: 3
          }
        }
      };

    case "line":
      return {
        ...commonOptions,
        chart: { ...commonOptions.chart, type: "line" },
        ...axisOptions,
        plotOptions: {
          series: {
            type: "line",
            dashStyle: "solid",
            marker: { enabled: false },
            enableMouseTracking: true
          }
        }
      };

    case "pie":
      return {
        ...commonOptions,
        chart: { ...commonOptions.chart, type: "pie", width: 550, height: 300 },
        plotOptions: {
          series: {
            borderWidth: 0,
            size: "100%",
            innerSize: "55%",
            dataLabels: {
              enabled: true,
              style: { color: "white"},
              formatter: function() {
                if (this.y != 0) {
                  return this.y.toFixed(1) + "%";
                } else {
                  return null;
                }
              },
              distance: -25
            },
            showInLegend: true
          }
        },
        legend: {
          ...commonOptions.legend,
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
          itemStyle: { fontSize: "12px", lineHeight: "25px", textAlign: "center", fontWeight: 400 },
          itemMarginBottom: 10
        }
      };

    default:
      return {};
  }
};
